import { memo, useState, useEffect } from 'react'
import { StaticMap as Map } from 'react-map-gl'
import { Pin } from '../Pin'
import { WebMercatorViewport } from 'react-map-gl'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

interface StaticMapProps {
  lat: number
  lng: number
  bounds?: string[]
}

export const StaticMap: React.FC<StaticMapProps> = memo(({ lat, lng, bounds }: StaticMapProps) => {
  const [mapConfig, setMapConfig] = useState({
    lat,
    lng,
    zoom: 13,
  })

  useEffect(() => {
    if (bounds) {
      console.log([bounds[2], bounds[0]], [bounds[3], bounds[1]])
      const boundsBox = new WebMercatorViewport({
        width: 600,
        height: 300,
      }).fitBounds([
        [Number(bounds[2]), Number(bounds[0])],
        [Number(bounds[3]), Number(bounds[1])],
      ] as any)

      setMapConfig({
        lat: boundsBox.latitude,
        lng: boundsBox.longitude,
        zoom: boundsBox.zoom,
      })
    }
  }, [bounds])

  return (
    <figure className="shadow-xl block rounded-xl overflow-hidden">
      <Map
        width="100%"
        height="300px"
        mapboxApiAccessToken={MAPBOX_TOKEN}
        latitude={mapConfig.lat}
        longitude={mapConfig.lng}
        mapStyle={`mapbox://styles/mapbox/streets-v11?optimize=true`}
        zoom={mapConfig.zoom}
        pitch={35}
        attributionControl={false}
        reuseMaps={true}
      >
        <Pin lat={lat} lng={lng} />
      </Map>
    </figure>
  )
})

StaticMap.displayName = 'StaticMap'
