/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Image from 'next/image'

import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { TypeCard } from '../components/TypeCard'

interface Props {
  submitCallback: (...args: any) => void
}

export const TypeStep = ({ submitCallback }: Props) => (
  <div className="grid grid-cols-12 pb-10 lg:pb-10">
    <div className="col-span-12 lg:col-span-12 lg:col-start-2">
      <header className="mb-5 md:mb-12">
        <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-5xl text-darker mb-3">
          Add a new review.
        </Heading>
        <div>
          <Text className="text-smoke-600 lg:text-lg mb-2 lg:mb-0">
            We’ll ask you some questions about the location, what’s included, your experience and more.
          </Text>
          <Text className="text-smoke-600 text-xs lg:text-sm">
            You will be asked to verify your email before you submit the review. Don’t worry, it will be posted
            anonymously.
          </Text>
        </div>
      </header>
      <Heading as="h3" className="text-2xl text-darker">
        What would you like to review?
      </Heading>
      <div className="w-full flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-6 md:items-stretch mt-4 md:mt-6">
        <TypeCard
          title="My rental unit or a building I've lived in."
          submitCallback={() => submitCallback({ reviewtype: 'building' })}
          description="Share your experience living in a specific unit, house, condo or building."
          image={
            <Image
              className="object-fill"
              src={'/images/building.png'}
              width="302"
              height="254"
              priority
              alt="Collection of Building Related Items"
              role="presentation"
            />
          }
        />
        <TypeCard
          title="A property management company."
          submitCallback={() => submitCallback({ reviewtype: 'company' })}
          description="Share your experience living in a unit managed by a property management company."
          image={
            <Image
              className="object-fill"
              src={'/images/landlord.png'}
              width="337"
              height="233"
              priority
              alt="Collection of Landlord Related Items"
              role="presentation"
            />
          }
        />
      </div>
    </div>
  </div>
)
