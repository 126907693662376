import { CustomLink } from '../utils/CustomLink'
import { ReactSVG } from 'react-svg'
import { motion, AnimateSharedLayout } from 'framer-motion'

interface Props {
  showSmall: boolean
}

const showSmallLogo = {
  open: { opacity: 1, y: 0 },
  closed: { opacity: 0, y: '-120%' },
}

export const Logo: React.FC<Props> = ({ showSmall }: Props) => {
  return (
    <div className="relative w-28 lg:w-32">
      <CustomLink href="/" className="no-underline leading-none inline-block w-full h-full">
        <span className="sr-only">Good Neighbour</span>
        <AnimateSharedLayout>
          <motion.div
            variants={showSmallLogo}
            animate={showSmall ? 'open' : 'closed'}
            className="absolute -top-1.5 lg:top-0 h-12"
          >
            <ReactSVG src="/altlogo.svg" className="w-full h-12" />
          </motion.div>

          <motion.div variants={showSmallLogo} animate={showSmall ? 'closed' : 'open'} className="h-12">
            <ReactSVG src="/smalllogo.svg" className="w-full h-12" />
          </motion.div>
        </AnimateSharedLayout>
      </CustomLink>
    </div>
  )
}

Logo.displayName = 'Logo'
