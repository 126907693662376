import cookie from 'js-cookie'
import { useEffect, useState } from 'react'

import { useStore } from '../state'
import { TOKEN_NAME } from '../auth'

export function useAuthenticate() {
  const [loggedIn, setLoggedIn] = useState(false)
  const isLoggedIn = useStore((state) => state.isLoggedIn)
  const userId = useStore((state) => state.userId)

  useEffect(() => {
    const token = cookie.get(TOKEN_NAME)
    if (token && isLoggedIn) {
      setLoggedIn(true)
    }
  }, [isLoggedIn])

  return {
    loggedIn,
    userId,
  }
}
