import { useState, useRef, useEffect, useMemo } from 'react'
import ReactMapGL, {
  GeolocateControl,
  NavigationControl,
  WebMercatorViewport,
  Popup,
  FlyToInterpolator,
} from 'react-map-gl'
import { lineString } from '@turf/helpers'
import bbox from '@turf/bbox'
import useSupercluster from 'use-supercluster'
import { Marker } from 'react-map-gl'

import { Text } from '../Text'
import { Rating } from '../Rating'
import { CustomLink } from '../utils/CustomLink'
import { useDebouncedCallback } from 'lib/hooks/useDebounce'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

interface Props {
  listings?: any[]
  isSearchQuery?: boolean
  center: {
    latitude: number
    longitude: number
    zoom: number
  } | null
  handleViewPortChange?: (args: {
    longitude: number
    latitude: number
    zoom: number
    bounds: { maxLat: number; minLat: number; maxLng: number; minLng: number }
  }) => void
}

const geolocateControlStyle = {
  right: 10,
  bottom: 110,
}

const navControlStyle = {
  right: 10,
  bottom: 40,
}

const defaultLocation = {
  zoom: 3,
  latitude: 46.789073,
  longitude: -100.475172,
}

export const Map: React.FC<Props> = ({
  listings = [],
  handleViewPortChange = () => null,
  center = null,
  isSearchQuery = false,
}: Props) => {
  const theMap = useRef<any | null>(null)
  const [viewport, setViewport] = useState<any>(defaultLocation)
  const [popupValue, setPopupValue] = useState<{ property: any; latitude: number; longitude: number } | null>()

  const bounds = theMap.current ? theMap.current.getMap().getBounds().toArray().flat() : null

  const geojson = useMemo(
    () =>
      listings
        .filter((listing) => listing.latitude !== null && listing.longitude !== null)
        .map((listing) => {
          return {
            type: 'Feature',
            properties: {
              cluster: false,
              category: listing.category,
              id: listing.id,
              address: listing.address,
              name: listing.name,
              rating: listing.rating,
              count: listing.reviewCount,
            },
            geometry: {
              type: 'Point',
              coordinates: [parseFloat(listing.longitude), parseFloat(listing.latitude)],
            },
          }
        })
        .filter((listing) => listing),
    [listings],
  )

  const { clusters, supercluster } = useSupercluster({
    points: geojson,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 75, maxZoom: 20 },
  })

  const calculateBounds = (listings) => {
    const boundslist = listings
      ?.filter((listing) => listing?.latitude && listing?.longitude)
      .map((listing) => {
        if (listing?.latitude && listing?.longitude) {
          return [listing?.longitude, listing?.latitude]
        }
      })

    if (boundslist.length > 1) {
      const line = lineString(boundslist as any)
      const boundsBox = bbox(line)

      const bounds = new WebMercatorViewport({
        width: 600,
        height: 800,
      }).fitBounds(
        [
          [boundsBox[0], boundsBox[1]],
          [boundsBox[2], boundsBox[3]],
        ] as any,
        { padding: 200 },
      )

      setViewport({
        latitude: bounds.latitude,
        longitude: bounds.longitude,
        zoom: bounds.zoom < 0 ? 1 : bounds.zoom,
      })
    } else if (boundslist.length === 1) {
      setViewport({
        latitude: boundslist[0].latitude,
        longitude: boundslist[0].longitude,
        zoom: 10,
      })
    }
  }

  useEffect(() => {
    if (listings) {
      calculateBounds(listings)
    }
  }, [])

  useEffect(() => {
    if (listings && isSearchQuery) {
      calculateBounds(listings)
    }
  }, [isSearchQuery, listings])

  useEffect(() => {
    if (center) {
      setViewport({
        latitude: center.latitude,
        longitude: center.longitude,
        zoom: center.zoom,
      })
    }
  }, [center])

  const handleClick = (lat: number, longitude: number, id: string) => {
    const property = listings.filter(
      (listing) => listing.listing?.id === id || listing.company?.id === id || listing.id === id,
    )[0]
    setPopupValue({ property, latitude: lat, longitude: longitude })
  }

  const adjustViewport = useDebouncedCallback(() => {
    if (theMap) {
      const m = theMap.current.getMap()
      const bounds = m.getBounds()
      const zoom = m.getZoom()
      const center = m.getCenter()
      handleViewPortChange({
        latitude: center.lat,
        longitude: center.lng,
        zoom,
        bounds: {
          maxLat: bounds._ne.lat,
          minLat: bounds._sw.lat,
          maxLng: bounds._ne.lng,
          minLng: bounds._sw.lng,
        },
      })
    }
  }, 250)

  return (
    <figure className="relative flex-1">
      <ReactMapGL
        {...viewport}
        ref={theMap}
        width="100%"
        height="100%"
        reuseMaps={true}
        onViewportChange={setViewport}
        pitch={35}
        mapStyle={`mapbox://styles/mapbox/outdoors-v11?optimize=true`}
        onMouseUp={(event: any) => {
          if (event.distance > 0) {
            adjustViewport()
          }
        }}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onTransitionEnd={adjustViewport}
      >
        <GeolocateControl
          style={geolocateControlStyle}
          positionOptions={{ enableHighAccuracy: true }}
          showAccuracyCircle
        />
        <NavigationControl
          showCompass={false}
          style={navControlStyle}
          capturePointerMove={true}
          onViewportChange={setViewport}
        />
        {popupValue ? (
          <Popup
            latitude={popupValue.latitude}
            longitude={popupValue.longitude}
            anchor="bottom"
            closeOnClick={false}
            offsetTop={-10}
            offsetLeft={17}
            onClose={() => setPopupValue(null)}
          >
            <div className="flex flex-col items-start">
              <Text className="text-xs text-smoke-800 mb-0 font-oakes">
                {popupValue.property.category === 'company' ? 'Company' : 'Listing'}
              </Text>
              <CustomLink
                href={
                  popupValue.property.category === 'company'
                    ? `/management/${popupValue.property.id}`
                    : `/listing/${popupValue.property.id}`
                }
                className="font-semibold text-sm text-darker no-underline block text-left leading-tight"
              >
                {popupValue.property.category === 'company' ? popupValue.property.name : popupValue.property.address}
              </CustomLink>
              {popupValue.property.rating ? (
                <Rating
                  value={popupValue.property.rating}
                  type="Review"
                  canPluralize={true}
                  count={popupValue.property.reviewCount}
                  className="text-violet-500 block"
                />
              ) : null}
            </div>
          </Popup>
        ) : null}
        {clusters.map((cluster) => {
          const [longitude, latitude] = cluster.geometry.coordinates
          const { cluster: isCluster, point_count: pointCount } = cluster.properties
          if (isCluster) {
            return (
              <Marker latitude={latitude} longitude={longitude} captureClick={false} key={cluster.id}>
                <button
                  className="relative rounded-full h-10 w-10 flex items-center justify-center shadow-lg bg-gradient-to-t to-darkbg from-darker text-xs border border-smoke-400 w-8 h-8 text-white font-semibold"
                  onClick={() => {
                    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20)
                    setPopupValue(null)
                    setViewport({
                      ...viewport,
                      latitude,
                      longitude,
                      zoom: expansionZoom,
                      transitionInterpolator: new FlyToInterpolator({
                        speed: 3,
                      }),
                      transitionDuration: 'auto',
                    })
                  }}
                >
                  {pointCount}
                </button>
              </Marker>
            )
          } else {
            return (
              <Marker latitude={latitude} longitude={longitude} captureClick={false} key={cluster.properties.id}>
                <button
                  className="rounded-full w-4 h-4 flex items-center justify-center text-white bg-darkbg shadow-sm"
                  onClick={() => {
                    handleClick(latitude, longitude, cluster.properties.id)
                  }}
                ></button>
              </Marker>
            )
          }
        })}
      </ReactMapGL>
    </figure>
  )
}

Map.displayName = 'Map'
