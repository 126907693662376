import clsx from 'clsx'
import { useState } from 'react'
import { useController } from 'react-hook-form'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  defaultValue?: string
  button?: JSX.Element[] | JSX.Element
  className?: string
  control?: any
  rules?: any
  height?: string
}

export const TextArea: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  isRequired,
  defaultValue,
  control,
  rules,
  className,
  height = 'h-72',
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const [currentValue, setCurrentValue] = useState(value)
  const id = name.replace(/\s/g, '') + '-hint'

  const updateValue = (e) => {
    setCurrentValue(e.target.value)
    onChange(e.target.value)
  }

  const classNames = clsx(
    'appearance-none',
    'border',
    'px-3',
    'py-2.5',
    'w-full',
    'bg-white',
    'rounded-md',
    'placeholder-smoke-500',
    'focus:outline-none focus:ring-1',
    'resize-y',
    height,
    invalid
      ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600'
      : 'border-smoke-500 focus:ring-darker focus:ring-2',
  )

  return (
    <div
      role="group"
      title={isDisabled ? 'Field is disabled' : ''}
      className={clsx(className, isDisabled && 'opacity-40', 'relative')}
    >
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={isRequired}
        hideLabel={hideLabel}
        className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-darker')}
      />
      <div className="">
        <textarea
          className={classNames}
          onChange={updateValue}
          ref={ref}
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          value={currentValue}
          aria-disabled={isDisabled}
          aria-invalid={invalid}
          aria-required={isRequired}
          aria-describedby={helpText && id}
        ></textarea>

        <div className="flex items-baseline justify-between">
          <div className="w-3/4">
            {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
            {!!error?.message && (
              <div role="alert">
                <HelpText id={id} isError>
                  {error.message}
                </HelpText>
              </div>
            )}
          </div>
          <p className="text-right text-xs text-darker">Count: {currentValue.length}</p>
        </div>
      </div>
    </div>
  )
}

TextArea.displayName = 'TextArea'
