import { logError } from './rollbar'

export const geocode = async (address: string): Promise<any> => {
  const options = {
    polygon_geojson: 1,
    format: 'json',
    addressdetails: 1,
    namedetails: 1,
    q: address,
    countrycodes: ['ca'],
    limit: 10,
  }

  try {
    const queryString = Object.keys(options)
      .map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(options[key])
      })
      .join('&')
    const request = await fetch(`https://nominatim.openstreetmap.org/search?${queryString}`).catch((err) => {
      logError('Error getting value from geocode in new review', err)
      throw err
    })

    const data = await request.json()
    if (data) {
      return data
    } else {
      throw 'Error fetching location'
    }
  } catch (err) {
    logError('Error getting value from geocode in new review', err)
    throw err
  }
}
