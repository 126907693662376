import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Heading, TextArea } from 'components'
import { PriceField } from '../components/PriceField'

interface Props {
  submitCallback: (...args: any) => void
  state: any
  category?: string
  name: string
}

export const DetailsStep = ({ submitCallback, state, category, name }: Props) => {
  const [dishwasherAvailable, setDishwasherAvailable] = useState<boolean | null>(
    typeof state.dishwasherAvailable === 'boolean' ? state.dishwasherAvailable : null,
  )
  const [airConditioningIncluded, setAirConditioningIncluded] = useState<boolean | null>(
    typeof state.airConditioningIncluded === 'boolean' ? state.airConditioningIncluded : null,
  )
  const [petsWelcome, setPetsWelcome] = useState<boolean | null>(
    typeof state.petsWelcome === 'boolean' ? state.petsWelcome : null,
  )

  const [noisyNeighbours, setNoisyNeighbours] = useState<boolean | null>(
    typeof state.noisyNeighbours === 'boolean' ? state.noisyNeighbours : null,
  )
  const [accessibility, setAccessibility] = useState<boolean | null>(
    typeof state.accessibility === 'boolean' ? state.accessibility : null,
  )
  const [pests, setPests] = useState<boolean | null>(typeof state.pests === 'boolean' ? state.pests : null)
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      petsWelcomeStory: state.petsWelcomeStory || '',
      noisyNeighboursStory: state.noisyNeighboursStory || '',
      accessibilityStory: state.accessibilityStory || '',
      pestsStory: state.pestsStory || '',
    },
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(async ({ petsWelcomeStory, noisyNeighboursStory, pestsStory, accessibilityStory }) => {
    const payload = {
      petsWelcomeStory,
      noisyNeighboursStory,
      pestsStory,
      dishwasherAvailable,
      airConditioningIncluded,
      petsWelcome,
      noisyNeighbours,
      pests,
      accessibilityStory,
      accessibility,
    }

    submitCallback(payload)
  })

  return (
    <div className="grid grid-cols-12 pb-6 lg:pb-10">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2">
        <header className="mb-4 md:mb-12">
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            New {category === 'building' ? 'rental' : 'property management'} review.
          </Heading>
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {name}
          </Heading>
        </header>
      </div>

      <div className="col-span-12 lg:col-span-8 lg:col-start-3">
        <form className="w-full" onSubmit={onSubmit}>
          <Heading as="h2" className="text-2xl md:text-3xl">
            Tell us about more about your experience.
          </Heading>

          {category === 'building' ? (
            <PriceField
              icon="dishwasher"
              alt="A mug"
              title="Does the unit have a dishwasher?"
              verifier={dishwasherAvailable}
              setCallback={setDishwasherAvailable}
              theme="yellow"
            />
          ) : null}

          {category === 'building' ? (
            <PriceField
              icon="airconditioner"
              alt="A Thermometer"
              title="Is air conditioning available?"
              verifier={airConditioningIncluded}
              setCallback={setAirConditioningIncluded}
              theme="violet"
            />
          ) : null}

          <PriceField
            icon="pets"
            alt="A bowl full of dog food"
            title="Are pets welcome?"
            verifier={petsWelcome}
            setCallback={setPetsWelcome}
            theme="green"
          >
            <TextArea
              control={control}
              height="h-36"
              name="petsWelcomeStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about pets."
            ></TextArea>
          </PriceField>

          <PriceField
            icon="accessibility"
            alt="Heart Icon"
            title="Is the unit accessibility-friendly?"
            verifier={accessibility}
            setCallback={setAccessibility}
            theme="yellow"
          >
            <TextArea
              control={control}
              name="accessibilityStory"
              height="h-36"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about the accessibility services."
            ></TextArea>
          </PriceField>

          <PriceField
            icon="noisy"
            alt="A headphones"
            title="Are the neighbours noisy?"
            verifier={noisyNeighbours}
            setCallback={setNoisyNeighbours}
            theme="violet"
          >
            <TextArea
              height="h-36"
              control={control}
              name="noisyNeighboursStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about the neighbours."
            ></TextArea>
          </PriceField>

          <PriceField
            icon="pests"
            alt="A road pylon"
            title="Any pests, insects or unwanted animals?"
            verifier={pests}
            setCallback={setPests}
            theme="green"
          >
            <TextArea
              height="h-36"
              control={control}
              name="pestsStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about unwelcome visitors."
            ></TextArea>
          </PriceField>

          <div className="flex justify-end mt-16">
            <button
              data-fullstory="Landlord"
              type="submit"
              className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
