import React, { useState } from 'react'
import { useForm, useWatch, Controller } from 'react-hook-form'

import { useGetLatestReviews } from 'lib/hooks/useGetLatestReviews'
import formatAddress from 'lib/utils/formatAddress'

import { Heading } from '../../Heading'
import { TextArea } from '../../TextArea'
import { Text } from '../../Text'
import { RatingInput } from '../../RatingInput'
import { DateInput } from '../../DateInput'
import { NumberInput } from '../../NumberInput'
import { Checkbox } from '../../Checkbox'
import { FileUpload } from '../../ImageUpload'
import { ReviewCardSmall } from '../../ReviewCardSmall'

interface Props {
  submitCallback: (...args: any) => void
  name: string
  state: any
  category?: string
  parentListing?: string
  bounds?: any[]
}

export const ReviewStep = ({ submitCallback, state, category, name, parentListing }: Props) => {
  const { reviews: latestReviews } = useGetLatestReviews({ limit: 4 })
  const [photos, setPhotos] = useState<any[]>(state.photos || [])
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tenancyStart: state.tenancyStart ? state.tenancyStart : '',
      tenancyEnd: state.tenancyEnd ? state.tenancyEnd : '',
      shareTenancy: state.shareTenancy ? state.shareTenancy : false,
      content: state.content || '',
      rating: state.rating || 0,
      stillLiveHere: state.stillLiveHere ? true : false,
      rentPrice: state.rentPrice || 0,
    },
  })

  const removeImage = (index: number) => {
    const dataVal = [...photos]
    dataVal.splice(index, 1)
    setPhotos(dataVal)
  }

  const handleImages = (data: any) => {
    const imageValues = data.map((result) => {
      return {
        fileId: result.response.body.fileId,
        filePath: result.response.body.filePath,
        fileType: result.response.body.fileType,
        height: result.response.body.height,
        width: result.response.body.width,
        fileName: result.response.body.name,
      }
    })

    const dataVal = [...photos, ...imageValues]
    setPhotos(dataVal)
  }

  const onSubmit = handleSubmit(
    async ({ rating, content, tenancyEnd, tenancyStart, rentPrice, stillLiveHere, shareTenancy }) => {
      const payload = {
        rating,
        content: content.trim(),
        tenancyStart: tenancyStart,
        tenancyEnd: tenancyEnd,
        stillLiveHere,
        shareTenancy,
        rentPrice,
        photos,
      }

      if (!shareTenancy && !tenancyStart.length && !tenancyEnd.length) {
        setError('tenancyStart', {
          type: 'manual',
          message: 'Tenancy is required if not hidden',
        })
        setError('tenancyEnd', {
          type: 'manual',
          message: 'Tenancy is required if not hidden',
        })
        return
      }

      submitCallback(payload)
    },
  )

  const stillLiveHereWatcher = useWatch({
    control,
    name: 'stillLiveHere',
    defaultValue: state.stillLiveHere,
  })

  const shareTenancyWatcher = useWatch({
    control,
    name: 'shareTenancy',
    defaultValue: state.shareTenancy,
  })

  const tenancyStartWatcher = useWatch({
    control,
    name: 'tenancyStart',
  })

  const tenancyEndWatcher = useWatch({
    control,
    name: 'tenancyEnd',
  })

  return (
    <div className="grid grid-cols-12 lg:gap-16 pb-6 lg:pb-10">
      <div className="col-span-12 lg:col-span-8">
        <header className="mb-6 md:mb-16">
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            Add new review.
          </Heading>
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {name}
          </Heading>
        </header>

        <div className="w-full">
          <form className="w-full" onSubmit={onSubmit}>
            <div className="space-y-8 lg:space-y-8">
              <div className="border-b pb-6 lg:pb-10">
                <Controller
                  control={control}
                  name="rating"
                  rules={{ required: true, min: 1 }}
                  render={({ field: { onChange, value } }) => (
                    <RatingInput
                      value={value}
                      label="How would you rate your overall experience?"
                      onChange={onChange}
                      error={errors.rating}
                      className="text-lg sm:text-2xl"
                    />
                  )}
                />
              </div>
              <div className="border-b pb-6 lg:pb-10">
                <Heading as="p" className="text-lg sm:text-2xl">
                  Share your experience.
                </Heading>
                <Text className="text-sm">
                  The following steps will allow you to provide detail about pets, the cleanliness of the property and
                  more.
                </Text>
                <TextArea
                  className="mt-2.5"
                  control={control}
                  name="content"
                  rules={{
                    required: 'Review content is required',
                    maxLength: {
                      value: 2500,
                      message: 'Content must be a maximum of 2500 characters',
                    },
                    minLength: {
                      value: 20,
                      message: 'Content must be a minimum of 20 characters',
                    },
                  }}
                  helpText="All reviews are anonymous, but be sure not to include any private details about yourself or others."
                  label="Your Review"
                  placeholder="The unit got great sun, but the neighbours were loud. Wonderful community in the building, but the air conditioning never worked..."
                ></TextArea>
              </div>

              <div className="border-b pb-6 lg:pb-10">
                <Heading as="p" className="text-lg sm:text-2xl mb-2.5">
                  Images
                </Heading>
                <FileUpload
                  parentId={`${parentListing}`}
                  handleImageUploads={handleImages}
                  photos={photos}
                  removeImage={removeImage}
                />
              </div>

              <div className="border-b pb-6 lg:pb-10">
                <Heading as="p" className="text-lg sm:text-2xl mb-4">
                  Tenancy Dates
                </Heading>
                <Checkbox name="shareTenancy" control={control} label="Hide my tenancy dates from the review." />
                <div className="grid grid-cols-12 gap-4 mt-4">
                  <DateInput
                    className="col-span-12 lg:col-span-4"
                    label="Move In Date*"
                    name="tenancyStart"
                    isDisabled={shareTenancyWatcher}
                    max={tenancyEndWatcher as any}
                    control={control}
                  />
                  <div className="col-span-12 lg:col-span-4">
                    <DateInput
                      className="mb-4"
                      label="Move Out Date"
                      name="tenancyEnd"
                      control={control}
                      isDisabled={shareTenancyWatcher || stillLiveHereWatcher}
                      min={tenancyStartWatcher as any}
                      max={new Date() as any}
                    />
                    <Checkbox
                      name="stillLiveHere"
                      disabled={shareTenancyWatcher}
                      control={control}
                      label="I still live here"
                    />
                  </div>
                </div>
              </div>

              {category === 'building' ? (
                <div className="border-b pb-6 lg:pb-10">
                  <Heading as="p" className="text-lg sm:text-2xl mb-4">
                    What was the total monthly rent of the unit?
                  </Heading>
                  <NumberInput
                    className="w-full md:w-1/2"
                    name="rentPrice"
                    label="Monthly Rent Price"
                    rules={{
                      min: {
                        value: 100,
                        message: 'Minimum price is $100',
                      },
                      max: {
                        value: 100000,
                        message: 'Maximum price is $100000',
                      },
                      required: 'Rent Price is required',
                      valueAsNumber: true,
                    }}
                    control={control}
                  />
                </div>
              ) : null}
            </div>

            <div className="flex justify-end mt-16" data-fullstory="Prices">
              <button
                data-fullstory="Details"
                type="submit"
                className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="hidden lg:block lg:col-span-4 pt-44">
        <Heading as="h3" className="text-xl mb-4">
          Recent reviews on Good Neighbour
        </Heading>
        {latestReviews?.map((review) => (
          <ReviewCardSmall
            id={review.category === 'company' ? review.company.id : review.listing.id}
            content={review.content}
            key={review.id}
            rating={review.rating}
            type={review.company ? 'company' : 'listing'}
            title={
              review.category === 'company'
                ? review.company.city
                  ? `${review.company.name} - ${review.company.city}`
                  : review.company.name
                : formatAddress(review.listing)
            }
            className="mb-8"
          />
        ))}
      </div>
    </div>
  )
}
