import clsx from 'clsx'
import { ReactSVG } from 'react-svg'
import { motion } from 'framer-motion'

import { Heading } from 'components'

type Props = {
  children?: React.ReactNode
  icon: string
  alt: string
  title: string
  setCallback: any
  verifier: boolean | null
  theme: 'green' | 'violet' | 'yellow'
}

export const PriceField: React.FunctionComponent<Props> = ({
  children,
  icon,
  title,
  setCallback,
  verifier,
  theme,
}: Props) => {
  const themeColour = (value: string) => {
    switch (value) {
      case 'green':
        return 'bg-green-100'
      case 'yellow':
        return 'bg-yellow-100'
      default:
        return 'bg-violet-50'
    }
  }

  return (
    <div className="flex flex-col md:flex-row mt-10 md:space-x-6 pb-10 border-b items-start">
      <div className="md:w-2/5 flex items-start">
        <div className={clsx('h-16 w-16 rounded-full p-3.5 flex-shrink-0', themeColour(theme))}>
          <ReactSVG src={`/svg/icons/${icon}.svg`} />
        </div>
        <Heading as="h2" className="text-base sm:text-xl ml-6 mb-0">
          {title}
        </Heading>
      </div>
      <div className="md:w-3/5 flex flex-col">
        <div className="flex space-x-2 mb-4 mt-2">
          <motion.button
            type="button"
            onClick={() => setCallback(true)}
            className={clsx(
              'font-oakes text-sm pt-2.5 tracking-wider pb-2 px-4 border border-green-500 rounded-full hover:bg-green-500 hover:text-white shadow-lg transition hover:shadow-xl',
              verifier ? 'text-white bg-green-500' : 'text-green-500 bg-white',
            )}
          >
            Yep.
          </motion.button>
          <motion.button
            type="button"
            onClick={() => setCallback(false)}
            className={clsx(
              'font-oakes text-sm pt-2.5 tracking-wider pb-2 px-4 border border-pinker-500 rounded-full shadow-lg hover:bg-pinker-500 hover:text-white shadow-lg transition hover:shadow-xl',
              verifier === false ? 'text-white bg-pinker-500' : 'text-pinker-500 bg-white',
            )}
          >
            Nope.
          </motion.button>
        </div>
        {children}
      </div>
    </div>
  )
}
