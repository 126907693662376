/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { ReactSVG } from 'react-svg'
import { RiErrorWarningLine } from 'react-icons/ri'

import { Rating } from '../Rating'
import { Text } from '../Text'

interface ReviewCardSmallProps {
  type: string
  rating: number
  content?: string
  price?: number
  title: string
  id?: any
  className?: string
}

export const ReviewCardSmall: React.FC<ReviewCardSmallProps> = ({
  type,
  rating,
  title,
  content = '',
  price,
  id,
  className = '',
}: ReviewCardSmallProps) => (
  <div className={clsx('relative', className)}>
    <div className="flex">
      <div className="text-smoke-500 mr-2 w-4">
        {type === 'company' ? <ReactSVG src="/svg/icons/business.svg" /> : <ReactSVG src="/svg/icons/pin.svg" />}
      </div>
      <div className="flex-1">
        <Link href={type === 'company' ? '/management/' + id : '/listing/' + id}>
          <a className="font-semibold block text-sm no-underline hover:underline overflow-ellipsis overflow-hidden mt-0.5 leading-tight font-oakes pr-10">
            {title}
          </a>
        </Link>
        <div className="flex items-center mt-1 mb-3">
          {rating ? <Rating value={rating} type="Review" canPluralize={true} className="text-violet mr-2" /> : null}
          {price ? (
            <Text className="text-xs text-smoke-600 relative top-px ml-1 font-sans font-medium">
              Average Rent: ${price}
            </Text>
          ) : null}
        </div>

        {content ? (
          <Text className="text-sm text-smoke-700 mt-1 !leading-[1.7em]">
            {content?.split(' ').splice(0, 50).join(' ') + '...' || ''}
          </Text>
        ) : null}

        <Link
          href={`/contact?type=${type === 'company' ? `company_error&mid=${id}` : `listing_error&lid=${id}`}`}
          passHref
        >
          <a
            className="no-underline text-xs text-smoke-500 hover:text-smoke-400 py-1 inline-block absolute right-0 top-0"
            title="Report"
          >
            <RiErrorWarningLine size="15" />
            <span className="sr-only">Report</span>
          </a>
        </Link>
      </div>
    </div>
  </div>
)
