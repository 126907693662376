import { fetcher } from './fetcher'
import { login } from './auth'
import { logError } from './rollbar'

export const SignupService = async ({ email, password, contactMe }) => {
  try {
    const response = await fetcher('auth/signup', {
      options: {
        body: {
          contactMe,
          password,
          email,
        },
        method: 'post',
      },
    })

    return {
      data: response.data,
      message: response.message,
      verified: response.verified,
    }
  } catch (err) {
    logError('Error signing up with magic link', err)
    throw err
  }
}

export const LoginService = async ({ email, password }) => {
  try {
    const response = await fetcher('auth/login', {
      options: {
        body: {
          email,
          password,
        },
        method: 'post',
      },
    })

    return {
      data: response.data,
      message: response.message,
      verified: response.verified,
    }
  } catch (err) {
    logError('Error logging in with magic link', err)
    throw err
  }
}

export const ResetPasswordService = async ({ token, password }) => {
  try {
    const response = await fetcher('auth/reset-password', {
      options: {
        body: {
          token,
          password,
        },
        method: 'post',
      },
    })

    return {
      data: response.data,
      message: response.message,
      verified: response.verified,
    }
  } catch (err) {
    logError('Error logging in with magic link', err)
    throw err
  }
}

export const ForgotPasswordService = async ({ email }) => {
  try {
    const response = await fetcher('auth/forgot-password', {
      options: {
        body: {
          email,
        },
        method: 'post',
      },
    })

    return {
      data: response.data,
      message: response.message,
    }
  } catch (err) {
    logError('Error setting forgot password', err)
    throw err
  }
}

export const verifyAccount = async (token: string) => {
  try {
    const response = await fetcher('auth/verify-account', {
      options: {
        body: {
          token,
        },
        method: 'post',
      },
    })

    login({
      token: response.data.token,
      id: response.data.id,
    })

    return {
      id: response.data.id,
    }
  } catch (err) {
    logError('Error validating with magic link', err)
    throw err
  }
}
