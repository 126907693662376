import clsx from 'clsx'

interface Props {
  children: JSX.Element[] | JSX.Element | (string | Element)[] | any
  size?: 'large' | 'small'
  className?: string
  id?: string
}

const bodyContent = (content: string) => {
  return content.split(/\n/)
}

export const Text: React.FC<Props> = ({ children, size, className = '' }: Props) => {
  const classNames = clsx({ 'text-sm': size === 'small' }, { 'text-lg': size === 'large' }, className)

  if (typeof children === 'string') {
    return (
      <>
        {bodyContent(children).map((paragraph, i) => (
          <p className={classNames} key={i}>
            {paragraph}
          </p>
        ))}
      </>
    )
  } else {
    return <p className={classNames}>{children}</p>
  }
}
