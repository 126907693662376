import clsx from 'clsx'
import { RiSearchLine } from 'react-icons/ri'
import { useController } from 'react-hook-form'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  type: 'text' | 'email' | 'search' | 'password' | 'tel' | 'hidden' | 'url'
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  pattern?: string
  value?: any
  button?: JSX.Element[] | JSX.Element
  className?: string
  defaultValue?: any
  control?: any
  rules?: any
}

export const Input: React.FC<Props> = ({
  type = 'text',
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  isRequired,
  pattern,
  button,
  className,
  defaultValue = '',
  control = undefined,
  rules,
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const id = name.replace(/\s/g, '') + '-hint'

  const classNames = clsx(
    'appearance-none',
    'border',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'rounded-md',
    'placeholder-smoke-600',
    'focus:outline-none focus:ring-darkbg',
    invalid
      ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600'
      : 'border-smoke-500 focus:ring-darkbg focus:ring-2',
    type === 'search' && 'pl-8',
    isDisabled && 'bg-smoke-100 border-smoke-700',
  )

  return (
    <div
      role="group"
      className={clsx(className, isDisabled && 'opacity-40')}
      title={isDisabled ? 'Field is disabled' : ''}
    >
      {type !== 'hidden' ? (
        <Label
          label={label}
          name={name}
          isInvalid={invalid}
          isRequired={isRequired}
          hideLabel={hideLabel}
          className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-darker')}
        />
      ) : null}
      <div className="flex">
        <div className="relative flex-1 mb-1 rounded-xl">
          <input
            className={classNames}
            onChange={onChange}
            ref={ref}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={isDisabled}
            value={value}
            pattern={pattern}
            autoComplete="false"
            autoCorrect="false"
            aria-disabled={isDisabled}
            aria-invalid={invalid}
            aria-required={isRequired}
            aria-describedby={helpText && id}
          />
          {type === 'search' ? (
            <div role="presentation" aria-hidden="true" className="absolute top-1/3 left-3">
              <RiSearchLine />
            </div>
          ) : null}
        </div>
        {button && <div className="ml-3">{button}</div>}
      </div>
      {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
      {!!error?.message && (
        <div role="alert">
          <HelpText id={id} isError>
            {error.message}
          </HelpText>
        </div>
      )}
    </div>
  )
}

Input.displayName = 'Input'
