import clsx from 'clsx'
import { useState } from 'react'
import { useController } from 'react-hook-form'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || ''

const padDigits = (digits) => {
  const desiredLength = 3
  const actualLength = digits.length

  if (actualLength >= desiredLength) {
    return digits
  }

  const amountToAdd = desiredLength - actualLength
  const padding = '0'.repeat(amountToAdd)

  return padding + digits
}

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, '$1')

const addDecimalToNumber = (number, separator) => {
  const centsStartingPosition = number.length - 2
  const dollars = removeLeadingZeros(number.substring(0, centsStartingPosition))
  const cents = number.substring(centsStartingPosition)
  return dollars + separator + cents
}

export const toCurrency = (value, separator = '.') => {
  const digits = getDigitsFromValue(value)
  const digitsWithPadding = padDigits(digits)
  return addDecimalToNumber(digitsWithPadding, separator)
}

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  separator?: string
  button?: JSX.Element[] | JSX.Element
  className?: string
  defaultValue?: any
  control?: any
  rules?: any
}

export const NumberInput: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  defaultValue,
  control,
  rules,
  className,
  separator = '.',
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const id = name.replace(/\s/g, '') + '-hint'
  const [currentValue, setCurrentValue] = useState(value || 0)

  const updateValue = (value) => {
    const valueAsCurrency = toCurrency(value, separator)
    onChange(valueAsCurrency)
    setCurrentValue(valueAsCurrency)
  }

  const classNames = clsx(
    'appearance-none',
    'border',
    'border-l-0',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'placeholder-smoke-500',
    'rounded-r-md',
    'focus:outline-none focus:ring-1',
    invalid
      ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600'
      : 'border-smoke-500 focus:ring-darker focus:ring-2',
    isDisabled && 'bg-smoke-200 border-opacity-20',
  )

  return (
    <div
      role="group"
      className={clsx(className, isDisabled && 'opacity-40')}
      title={isDisabled ? 'Field is disabled' : ''}
    >
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={rules.required}
        hideLabel={hideLabel}
        className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-darker')}
      />
      <div className="flex rounded-xl">
        <div className={clsx('p-3 rounded-l-md bg-darkbg border border-smoke-500 text-white')}>$</div>
        <input
          inputMode="decimal"
          className={classNames}
          onChange={(e) => updateValue(e.target.value)}
          value={currentValue}
          ref={ref}
          type="text"
          name={name}
          id={name}
          placeholder={placeholder}
          disabled={isDisabled}
          autoComplete="false"
          autoCorrect="false"
          aria-disabled={isDisabled}
          aria-invalid={invalid}
          aria-required={rules.required}
          aria-describedby={helpText && id}
        />
      </div>
      <div className="px-10 mt-0.5">
        {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
        {!!error?.message && (
          <div role="alert">
            <HelpText id={id} isError>
              {error.message}
            </HelpText>
          </div>
        )}
      </div>
    </div>
  )
}

NumberInput.displayName = 'NumberInput'
