import clsx from 'clsx'
import { IoIosStar } from 'react-icons/io'
import { Text } from '../Text'

interface Props {
  className?: string
  value: number | string
  helpText?: string
  type?: string
  count?: number | string
  canPluralize?: boolean
}

export const Rating: React.FC<Props> = ({ value = 1, type, canPluralize, className, count }: Props) => (
  <div className={clsx('inline-flex space-x-2 items-center', className)}>
    <ul className="inline-flex space-x-1" role="presentation" aria-hidden>
      {Array(Number(Math.floor(value as number)))
        .fill(null)
        .map((star, i) => (
          <li key={i}>
            <IoIosStar className={'h-4 w-4 text-current'} />
          </li>
        ))}
    </ul>
    <span className="sr-only">Rating: {value}</span>
    {type && count && (
      <Text aria-live="polite" className="font-semibold hidden md:block">
        {count} {type}
        {Number(count) > 1 && canPluralize && 's'}
      </Text>
    )}
  </div>
)

Rating.displayName = 'Rating'
