/* eslint-disable react/no-unescaped-entities */
import { useState, useRef, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { RiSearchLine } from 'react-icons/ri'
import { StaticMap as Map } from 'react-map-gl'
import { Pin } from 'components/Pin'
import { useDebouncedCallback } from 'lib/hooks/useDebounce'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

import { provinces, states } from 'lib/utils/regions'
import { uppercaseEachWord } from 'lib/utils/stringFormatting'
import { geocode } from 'lib/geocode'

import { Text, Heading, Input, PhoneInput, Select, StockInput } from 'components'

interface Props {
  submitCallback: (...args: any) => void
  state: any
  category?: string
}

const us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$')
const ca = new RegExp(/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i)

export const AddressStep = ({ submitCallback, state, category }: Props) => {
  const node = useRef<HTMLDivElement>(null)
  const isListing = category === 'building'
  const [bounds, setBounds] = useState([])
  const [resultsLoading, setResultsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<any | null>()
  const [addressResult, setAddressResult] = useState<string | null>(
    state.city
      ? `${state.streetnumber} ${state.streetname}, ${state.neighbourhood}, ${state.city}, ${state.region} ${state.country} ${state.postalzip}`
      : '',
  )
  const [mapConfig, setMapConfig] = useState<{
    lat: number
    lng: number
    zoom: number
  } | null>(null)
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      country: state.country || 'Canada',
      region: state.region || 'ON',
      type: isListing ? state.type || 'house' : state.type || 'property_management',
      unitnumber: state.unitnumber || '',
      bedrooms: state.bedrooms || 1,
      bathrooms: state.bathrooms || 1,
      streetnumber: state.streetnumber || '',
      streetname: state.streetname || '',
      neighbourhood: state.neighbourhood || '',
      city: state.city || '',
      postalzip: state.postalzip || '',
      phoneNumber: state.phoneNumber || '',
      email: state.email || '',
      website: state.website || '',
      name: state.name || '',
      searchValue: '',
      latitude: state.latitude || '',
      longitude: state.longitude || '',
    },
    mode: 'onSubmit',
  })

  useEffect(() => {
    if (state.latitude && state.longitude) {
      setMapConfig({ lat: state.latitude, lng: state.longitude, zoom: 15 })
    }
  }, [state])
  const resetForm = () => {
    reset()
    setAddressResult(null)
  }

  const onSubmit = handleSubmit(
    async ({
      unitnumber,
      streetnumber,
      streetname,
      neighbourhood,
      bedrooms,
      bathrooms,
      city,
      country,
      region,
      postalzip,
      type,
      phoneNumber,
      email,
      website,
      name,
    }) => {
      let payload = {}
      if (isListing) {
        payload = {
          streetnumber: streetnumber.trim(),
          streetname: uppercaseEachWord(streetname.trim()),
          city: uppercaseEachWord(city.trim()),
          neighbourhood: uppercaseEachWord(neighbourhood),
          country: 'Canada',
          region,
          postalzip: postalzip.toUpperCase(),
          latitude: mapConfig?.lat,
          longitude: mapConfig?.lng,
          bounds,
          type,
          bedrooms,
          bathrooms,
        }
        if (unitnumber) {
          payload['unitnumber'] = uppercaseEachWord(unitnumber)
        }
      } else {
        payload = {
          streetnumber: streetnumber.trim(),
          streetname: uppercaseEachWord(streetname.trim()),
          city: uppercaseEachWord(city.trim()),
          country: 'Canada',
          region,
          postalzip: postalzip.toUpperCase(),
          type: 'property_management',
          email: email.trim(),
          website: website.trim(),
          name: name.trim(),
          phoneNumber: phoneNumber.trim(),
          latitude: mapConfig?.lat,
          longitude: mapConfig?.lng,
          bedrooms,
          bathrooms,
        }
      }
      submitCallback(payload)
    },
  )

  const country = useWatch({
    control,
    name: 'country',
    defaultValue: 'Canada',
  })
  const type = useWatch({
    control,
    name: 'type',
    defaultValue: 'house',
  })

  const findGeocodedAddress = useDebouncedCallback(async (value) => {
    setResultsLoading(true)
    if (value.length) {
      try {
        const res = await geocode(value)
        setResultsLoading(false)
        setSearchResults(res)
      } catch (e) {
        setResultsLoading(false)
      }
    } else {
      setResultsLoading(false)
    }
  }, 500)

  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setSearchResults(null)
  }

  useEffect(() => {
    if (searchResults?.length) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchResults])

  const setResultValues = (address, bounding_box, lat, lng) => {
    const streetnumber = address.house_number
    const streetname = address.road
    const neighbourhood = address.neighbourhood || address.suburb || ''
    const city = address.city || address.village || address.town
    const country = address.country_code === 'us' ? 'USA' : 'Canada'
    const postalzip = address.postcode
    let region
    setMapConfig({ lat, lng, zoom: 15 })

    if (address.country_code === 'us') {
      const stateValue = Object.entries(states).find((state) => state[1] === address.state)
      if (stateValue) {
        setValue('region', stateValue[0])
        region = stateValue[0]
      }
    } else {
      const provinceValue = Object.entries(provinces).find((province) => province[1] === address.state)
      if (provinceValue) {
        console.log(provinceValue[0])
        setValue('region', provinceValue[0])
        region = provinceValue[0]
      }
    }

    setAddressResult(`${streetnumber} ${streetname}, ${city}, ${region}, ${neighbourhood}, ${country}, ${postalzip}`)
    setValue('streetnumber', streetnumber)
    setValue('streetname', streetname)
    setValue('neighbourhood', neighbourhood)
    setValue('city', city)
    setValue('country', country)
    setValue('postalzip', postalzip)
    setValue(
      'searchValue',
      `${streetnumber} ${streetname}, ${city}, ${region}, ${neighbourhood}, ${country}, ${postalzip}`,
    )
    setBounds(bounding_box)
    setSearchResults(null)
  }

  const requiresUnitNumber = type !== 'house' && type !== 'building'
  const isCanada = country === 'Canada'
  return (
    <div className="grid grid-cols-12 pb-6 lg:pb-10">
      <div className="col-span-12 lg:col-span-12">
        <header className="mb-0 md:mb-4">
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            New {category === 'building' ? 'rental' : 'property management'} review.
          </Heading>
        </header>
        <div className="mb-2 md:mb-8">
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {isListing ? "What's the unit address?" : 'Property Management Company'}
          </Heading>
          {isListing ? null : (
            <Text className="text-smoke-600 text-sm mt-4 border border-smoke-600  p-4 rounded-md inner-shadow-sm">
              Note: Please do not review independent landlords - instead, include those details within a building
              review. All personal information will be removed.
            </Text>
          )}
        </div>

        <div className="w-full">
          <div className="relative flex" ref={node}>
            <div className="relative w-full">
              <StockInput
                type="search"
                label="Search for an address."
                name="searchValue"
                sentValue={addressResult}
                onChange={findGeocodedAddress}
                placeholder="123 Main Street"
                helpText="We'll populate the full address for you."
                className="w-full"
              />
              {resultsLoading ? (
                <div
                  className="inline-block w-8 h-8 rounded-full border-4 border-solid animate-spin absolute bottom-8 right-4"
                  style={{ borderColor: 'rgba(10,29,47,0.4)', borderTopColor: '#001000' }}
                  aria-label="Loading"
                  role="status"
                ></div>
              ) : null}
            </div>

            {searchResults?.length >= 1 ? (
              <div className="absolute rounded-md shadow-lg border border-smoke-100 z-30 top-20 w-full max-h-48 overflow-hidden">
                <ul className="bg-white divide-solid divide-y top-20 w-full max-h-48 overflow-y-scroll">
                  {searchResults.map((result) => {
                    const address = result.address
                    if (address?.house_number && address?.road && address?.city) {
                      const addressString = `${address.house_number} ${address.road}, ${
                        address.city || address.town || address.village
                      }, ${address.state}, ${address.country}`
                      return (
                        <li key={result.place_id}>
                          <button
                            onClick={() =>
                              setResultValues(
                                result.address,
                                result?.bounding_box,
                                Number(result?.lat),
                                Number(result?.lon),
                              )
                            }
                            className="text-xs lg:text-sm font-oakes mb-0.5 text-darker py-5 px-3"
                          >
                            {addressString}
                          </button>
                        </li>
                      )
                    } else {
                      return null
                    }
                  })}
                </ul>
              </div>
            ) : null}
          </div>

          {mapConfig ? (
            <div className="mt-6">
              <figure className="shadow-xl block rounded-xl overflow-hidden">
                <Map
                  width="100%"
                  height="300px"
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  latitude={mapConfig.lat}
                  longitude={mapConfig.lng}
                  mapStyle={`mapbox://styles/mapbox/streets-v11?optimize=true`}
                  zoom={mapConfig.zoom}
                  pitch={35}
                  attributionControl={false}
                  reuseMaps={true}
                >
                  <Pin lat={mapConfig.lat} lng={mapConfig.lng} />
                </Map>
              </figure>
            </div>
          ) : null}

          <form className="w-full" onSubmit={onSubmit}>
            <>
              {!isListing ? (
                <div className="space-y-4 pb-6 pt-6">
                  <div className="flex space-y-4 lg:space-y-0 lg:space-x-6 flex-col lg:flex-row">
                    <Input
                      type="text"
                      label="Company Name"
                      name="name"
                      control={control}
                      rules={{
                        required: 'Company name is required',
                      }}
                      placeholder="Property Management Inc."
                      className="w-full lg:w-2/3"
                    />
                  </div>

                  <div className="flex space-y-4 lg:space-y-0 lg:space-x-6 flex-col lg:flex-row">
                    <Input
                      type="email"
                      label="Email Address"
                      name="email"
                      control={control}
                      helpText="General contact email"
                      placeholder="biz@landlord.com"
                      className="w-full lg:w-1/2"
                    />
                    <Input
                      type="url"
                      label="Website"
                      name="website"
                      control={control}
                      rules={{
                        pattern: `https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)
                    `,
                      }}
                      placeholder="www.landlord.com"
                      className="w-full lg:w-1/2"
                    />
                    <PhoneInput
                      label="Phone Number"
                      name="phoneNumber"
                      control={control}
                      rules={{
                        pattern: '[0-9]{3}-[0-9]{3}-[0-9]{4}',
                      }}
                      helpText="General phone number"
                      className="w-full lg:w-1/2"
                    />
                  </div>
                </div>
              ) : null}

              <div className="block border-t py-8 space-y-6 mt-8">
                <div className="grid lg:grid-cols-12 gap-4 sm:gap-8">
                  <Input
                    type="text"
                    label="Street Number"
                    name="streetnumber"
                    control={control}
                    rules={
                      isListing && {
                        required: 'Street number is required',
                      }
                    }
                    placeholder="Street Number"
                    className="col-span-12 lg:col-span-2"
                  />

                  <Input
                    type="text"
                    label="Street Name"
                    name="streetname"
                    control={control}
                    rules={
                      isListing && {
                        required: 'Street name is required',
                      }
                    }
                    placeholder="Street Name"
                    className="col-span-12 lg:col-span-5"
                  />
                  <Input
                    type="text"
                    label="City"
                    name="city"
                    control={control}
                    rules={isListing && { required: 'City is required' }}
                    placeholder="City"
                    className="col-span-12 lg:col-span-5"
                  />
                </div>

                <div className="grid lg:grid-cols-12 gap-4 sm:gap-8">
                  <Input
                    type="text"
                    label="Neighbourhood"
                    name="neighbourhood"
                    control={control}
                    className="col-span-12 lg:col-span-3"
                    rules={
                      isListing && {
                        min: {
                          value: 4,
                          message: 'Minimum of 4 characters',
                        },
                      }
                    }
                    placeholder="Neighbourhood"
                  />
                  <Select
                    label={isCanada ? 'Province' : 'State'}
                    name="region"
                    control={control}
                    className="col-span-12 lg:col-span-3"
                  >
                    {Object.entries(isCanada ? provinces : states).map(([optionValue, optionName]) => (
                      <option key={optionValue} value={optionValue}>
                        {optionName}
                      </option>
                    ))}
                  </Select>
                  {/* <Select label="Country" name="country" control={control} className="col-span-12 lg:col-span-3">
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                  </Select> */}
                  <Input
                    className="col-span-12 lg:col-span-3"
                    type="text"
                    label={isCanada ? 'Postal Code' : 'Zip Code'}
                    name="postalzip"
                    control={control}
                    rules={
                      isListing && {
                        required: isCanada ? 'Postal Code is required' : 'Zip Code is required',
                        pattern: {
                          value: isCanada ? ca : us,
                          message: `${isCanada ? 'Postal Code' : 'Zip Code'} is not valid`,
                        },
                      }
                    }
                    placeholder={isCanada ? 'Postal Code' : 'Zip Code'}
                  />
                </div>
              </div>
              {isListing ? (
                <div>
                  <div className="grid border-t py-6 lg:grid-cols-12 gap-4 sm:gap-8">
                    <Input
                      isDisabled={type === 'house' || type === 'building'}
                      type="text"
                      label="Unit Number or location"
                      name="unitnumber"
                      className="col-span-12 lg:col-span-3"
                      control={control}
                      rules={{
                        required: {
                          value: requiresUnitNumber,
                          message: 'Unit number is required',
                        },
                      }}
                      placeholder="Unit Number"
                    />
                    <Select label="Bedrooms" name="bedrooms" control={control} className="col-span-12 lg:col-span-2">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3+">3+</option>
                    </Select>

                    <Select label="Bathrooms" name="bathrooms" control={control} className="col-span-12 lg:col-span-2">
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3+">3+</option>
                    </Select>
                    <Select label="Type of Listing" name="type" control={control} className="col-span-12 lg:col-span-2">
                      <option value="apartment">Apartment</option>
                      <option value="house">House</option>
                      <option value="condo">Condo</option>
                      <option value="room">Room</option>
                      <option value="building">Building</option>
                    </Select>
                  </div>
                </div>
              ) : null}
            </>

            <div className="flex space-x-4 justify-end">
              <button onClick={resetForm} className="text-smoke-900 text-sm">
                Reset
              </button>
              <button
                data-fullstory="Review"
                type="submit"
                className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
              >
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
