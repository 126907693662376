import clsx from 'clsx'

export interface Props {
  children: React.ReactNode
  className?: string
  style?: any
}

export const Container: React.FunctionComponent<Props> = ({ children, className, style }: Props) => (
  <div className={clsx('container mx-auto px-6 md:px-2', className)} style={style}>
    {children}
  </div>
)

Container.displayName = 'Container'
