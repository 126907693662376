import { useMemo, useState } from 'react'
import ReactMapGL, { NavigationControl, Marker, Popup } from 'react-map-gl'
import clsx from 'clsx'
import { FaStethoscope, FaTree, FaPills, FaTools, FaGlasses, FaBook } from 'react-icons/fa'
import { MdLocalGroceryStore, MdPets, MdLocalLaundryService, MdSchool } from 'react-icons/md'
import { IoMdPin } from 'react-icons/io'
import { AiFillBank } from 'react-icons/ai'
import { BiHomeHeart } from 'react-icons/bi'
import find from 'lodash.find'
import { Text, Heading } from 'components'
import { Pin } from '../Pin'

function formatType(type: string) {
  switch (type) {
    case 'grocery' || 'discountstore':
      return <MdLocalGroceryStore size="14" />
    case 'chiropractors' ||
      'acupunture' ||
      'health' ||
      'physicaltherapy' ||
      'generaldentistry' ||
      'lifecoach' ||
      'c_and_mh' ||
      'surgeons' ||
      'drugstores' ||
      'oralsurgeons':
      return <FaStethoscope size="14" />
    case 'pharmacy':
      return <FaPills size="14" />
    case 'vet':
      return <MdPets size="14" />
    case 'dryclean' || 'laundry' || 'laundryservices' || 'sewingalterations':
      return <MdLocalLaundryService size="14" />
    case 'homedecor' || 'hardware' || 'locksmiths':
      return <FaTools size="14" />
    case 'galleries':
      return <BiHomeHeart size="14" />
    case 'daycamps' || 'parks' || 'recreation':
      return <FaTree size="14" />
    case 'libraries' || 'childcare' || 'privatetutors':
      return <FaBook size="14" />
    case 'banks':
      return <AiFillBank size="14" />
    case 'elementaryschools' || 'highschools' || 'montessori' || 'preschools' || 'religiousschools':
      return <MdSchool size="14" />
    case 'optometrists':
      return <FaGlasses size="14" />
    default:
      return <IoMdPin size="14" />
  }
}

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

const navControlStyle = {
  right: 10,
  bottom: 40,
}

interface HoodMapProps {
  lat: number
  lng: number
  zoom: number
  handleMapMove: any
  showUserPin?: boolean
  businesses: any[]
}

export const HoodMap: React.FC<HoodMapProps> = ({
  lat,
  lng,
  zoom,
  handleMapMove,
  businesses,
  showUserPin = false,
}: HoodMapProps) => {
  const [categories, setCategories] = useState<any[]>([])
  const [popInfo, setPopInfo] = useState<any | null>(null)

  const updateValues = (data) => {
    if (categories.includes(data)) {
      const filteredArray = categories.filter((category) => category !== data)
      setCategories(filteredArray)
    } else {
      setCategories([...categories, data])
    }
  }

  const businessProperties = useMemo(
    () =>
      businesses.reduce((list, business) => {
        return find(list, { alias: business.categories[0].alias, name: business.categories[0].title })
          ? list
          : [...list, { alias: business.categories[0].alias, name: business.categories[0].title }]
      }, []),
    [businesses],
  )

  const renderPopup = () => (
    <Popup
      tipSize={5}
      anchor="bottom"
      longitude={popInfo.coordinates.longitude}
      latitude={popInfo.coordinates.latitude}
      closeOnClick={false}
      dynamicPosition
      onClose={() => setPopInfo(null)}
    >
      <div>
        <a href={popInfo.url} className="no-underline">
          <Text className="text-blue-600 text-xs no-underline">{popInfo.categories[0].title}</Text>
          <Text className="text-darker font-semibold font-oakes text-sm no-underline">{popInfo.name}</Text>
        </a>
      </div>
    </Popup>
  )

  return (
    <div className="md:grid grid-cols-12 gap-6 items-start">
      <figure className="relative shadow-lg rounded-md overflow-hidden col-span-8">
        <ReactMapGL
          latitude={lat}
          longitude={lng}
          zoom={zoom}
          width="100%"
          height="500px"
          reuseMaps={true}
          onViewportChange={handleMapMove}
          pitch={35}
          mapStyle={`mapbox://styles/mapbox/outdoors-v11?optimize=true`}
          mapboxApiAccessToken={MAPBOX_TOKEN}
        >
          <NavigationControl
            showCompass={false}
            style={navControlStyle}
            capturePointerMove={true}
            onViewportChange={handleMapMove}
          />
          {businesses
            ? businesses
                .filter((biz) => (categories.length ? categories.includes(biz.categories[0].alias) : biz))
                .map((business) => (
                  <Marker
                    latitude={business.coordinates.latitude}
                    longitude={business.coordinates.longitude}
                    captureClick={false}
                    key={business.id}
                  >
                    <button
                      className={clsx(
                        'rounded-full flex items-center justify-center shadow-lg',
                        business.categories[0].alias
                          ? 'bg-white border border-smoke-300 w-8 h-8 text-darker'
                          : 'bg-gradient-to-b from-blue-500 to-blue-600 w-4 h-4 text-white',
                      )}
                      onClick={() => setPopInfo(business)}
                      title={business.name}
                    >
                      {business.categories[0].alias ? formatType(business.categories[0].alias) : null}
                    </button>
                  </Marker>
                ))
            : null}
          {popInfo && renderPopup()}
          {showUserPin ? <Pin lat={lat} lng={lng} /> : null}
        </ReactMapGL>
      </figure>
      <div className="col-span-4 mt-6 md:mt-0">
        <Text className="text-xl lg:text-2xl text-darker mb-4 font-oakes tracking-tight">Categories.</Text>
        <form className="flex justify-start content-start flex-wrap">
          {businessProperties.map((businessValues) => (
            <label
              htmlFor={businessValues.alias}
              className="block relative mr-2 mb-2 md:mr-2 md:mb-3"
              key={businessValues.alias}
            >
              <input
                type="checkbox"
                id={businessValues.alias}
                className="next:border-darker next:border-1 next:ring-1 next:text-white  next:bg-darker appearance-none h-0 absolute"
                checked={!!categories.filter((category) => category === businessValues.alias)[0]}
                onChange={() => updateValues(businessValues.alias)}
              />
              <span className="block border border-darker text-xs px-2.5 md:px-3.5 py-1.5 md:pt-2.5 md:pb-2 rounded-full cursor-pointer hover:border-smoke-600 bg-white text-darker font-oakes">
                {businessValues.name}
              </span>
            </label>
          ))}
          <button
            type="button"
            className="appearance-none mr-3 mb-3 py-2 rounded-md text-xs text-smoke-700 font-oakes"
            onClick={() => setCategories([])}
          >
            Clear Filters
          </button>
        </form>
      </div>
    </div>
  )
}

HoodMap.displayName = 'HoodMap'
