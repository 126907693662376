import cookie from 'js-cookie'
import { TOKEN_NAME } from './auth'

export const fetcher = async (
  url: string,
  args?: {
    options?: { body?: any; method?: string }
    serverside?: boolean
  },
) => {
  const token = cookie.get(TOKEN_NAME)

  const optionsConfig: any = {
    method: args?.options?.method ? args.options.method : 'get',
    credentials: 'include',
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  }

  if (token) {
    optionsConfig.headers.authorization = `Bearer ${token}`
  }

  if (args?.options?.method) {
    optionsConfig.body = JSON.stringify({ ...args?.options.body })
  }

  const baseURL = process.env.NEXT_PUBLIC_API_ENDPOINT
  try {
    const request = await fetch(`${baseURL}/${url}`, optionsConfig).catch((err) => {
      throw err
    })

    const data = await request.json()
    if (data.success) {
      return data
    } else {
      throw data
    }
  } catch (err) {
    throw err
  }
}
