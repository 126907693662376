import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import { RiCloseLine } from 'react-icons/ri'
import { useEffect, useMemo } from 'react'
import cx from 'clsx'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import '@uppy/image-editor/dist/style.css'

const IMAGEKIT_ENDPOINT = process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT

export interface IKResponse {
  fileId: string
  filePath: string
  fileType: string
  height: number
  width: number
  name: string
  thumbnailUrl: string
  url: string
}

import { fetcher } from '../../lib/fetcher'
import { Text } from '../Text'

import ImageKitPlugin from './plugin'

interface Props {
  handleImageUploads: ([]) => any
  parentId: string
  photos?: any[]
  removeImage: (index: number) => any
}

export const FileUpload: React.FC<Props> = ({ handleImageUploads, photos = [], removeImage }: Props) => {
  const uppy = useMemo(() => {
    return Uppy({
      allowMultipleUploads: true,
      autoProceed: true,
      restrictions: { maxFileSize: 10 ** 6 * 5, allowedFileTypes: ['image/*'] },
    })
      .use(ImageKitPlugin, {
        id: 'ImageKit',
        folder: '/p',
      })
      .on('complete', (results) => {
        handleImageUploads(results.successful)
      })
  }, [])

  useEffect(() => {
    return () => uppy.close()
  }, [])

  const handleRemove = async (fileIndex: number, fileId: string, id = '', e) => {
    e.preventDefault()
    try {
      const response = await fetcher(`auth/assets/${fileId}?id=${id}`, {
        options: {
          method: 'delete',
        },
      })

      if (response.success) {
        removeImage(fileIndex)
      }
    } catch (err) {
      throw err
    }
  }

  const fileGroups = photos.map((file, i) => (
    <li
      className="flex-1/4 rounded-md border-brand border-1 mb-4 mr-4 w-24 h-24 overflow-hidden shadow-md hover:shadow-lg relative transition-all"
      key={i}
    >
      <img
        src={`${IMAGEKIT_ENDPOINT}/tr:n-media_library_thumbnail${file.filePath}`}
        className={cx('object-cover object-center h-24 w-full')}
        alt={file.name}
      />
      <button
        className="absolute -top-0.5 -right-0.5 z-10 bg-red-500 text-light rounded-md p-1 hover:bg-red-700 transition-all"
        title="Remove Image from Review"
        onClick={(e) => handleRemove(i, file.fileId, file.id, e)}
      >
        <span className="sr-only">Remove Image</span>
        <RiCloseLine />
      </button>
    </li>
  ))

  return (
    <div>
      <Dashboard
        width="100%"
        height="450px"
        uppy={uppy}
        plugins={['ImageEditor']}
        proudlyDisplayPoweredByUppy={false}
        note="Images up to 5mb only."
        theme={'light'}
        disableStatusBar
      />
      {!!fileGroups.length && (
        <div className="mt-4">
          <Text className="text-sm font-semibold">Uploaded Files</Text>
          <ul className="flex flex-wrap mt-2 border-brand">{fileGroups}</ul>
        </div>
      )}
    </div>
  )
}
