import clsx from 'clsx'
import { useController } from 'react-hook-form'

import { FiChevronDown } from 'react-icons/fi'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  name: string
  placeholder?: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  variant?: 'sm' | 'md' | 'lg'
  children: JSX.Element[] | JSX.Element
  className?: string
  defaultValue?: any
  control?: any
  rules?: any
}

export const Select: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  isDisabled,
  helpText,
  isRequired,
  className,
  children,
  defaultValue,
  control,
  rules,
}: Props) => {
  const id = name.replace(/\s/g, '') + '-hint'
  const {
    field: { ref, value, onBlur, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const cx = clsx(
    'appearance-none',
    'border',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'placeholder-smoke-500',
    'rounded-md',
    'focus:outline-none focus:ring-2 focus:ring-blue-600',
    invalid
      ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600'
      : 'border-smoke-500 border-1 focus:ring-blue-600',
  )

  return (
    <div role="group" className={clsx(className, isDisabled && 'opacity-40')}>
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={isRequired}
        hideLabel={hideLabel}
        className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-smoke-800')}
      />
      <div className="relative rounded-xl">
        <select
          ref={ref}
          name={name}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isDisabled}
          required={isRequired}
          value={value}
          aria-disabled={isDisabled}
          aria-invalid={invalid}
          aria-required={isRequired}
          aria-describedby={helpText && id}
          className={cx}
        >
          {children}
        </select>
        <div className={clsx('absolute top-4 right-3', invalid && 'text-pinker-700', 'pointer-events-none')}>
          <FiChevronDown className="text-darker text-2xl" />
        </div>
      </div>
      {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
      {!!error?.message && (
        <div role="alert">
          <HelpText id={id} isError>
            {error.message}
          </HelpText>
        </div>
      )}
    </div>
  )
}

Select.displayName = 'Select'
