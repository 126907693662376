import { motion, AnimateSharedLayout, useReducedMotion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { useRouter } from 'next/router'
import clsx from 'clsx'

import { AddressStep } from './Step/AddressStep'
import { RentAddressStep } from './Step/RentAddressStep'
import { TypeStep } from './Step/TypeStep'
import { ReviewStep } from './Step/ReviewStep'
import { LandlordStep } from './Step/LandlordStep'
import { PriceStep } from './Step/PriceStep'
import { DetailsStep } from './Step/DetailsStep'
import { UnitStep } from './Step/UnitStep'
import { RentIntro } from './Step/RentIntro'
import { ReviewSection } from './ReviewSection'
import formatAddress from 'lib/utils/formatAddress'
import { useStore } from 'lib/state'
import { Spinner, Text } from 'components'

export type StepType = {
  name: string
  display: string
  values: any[] | any
}

interface Props {
  steps: StepType[]
  handleCallback: any
  isSubmitting: boolean
  reviewType?: string
  name?: string
}

const displayMobileSteps = (currentStep, steps) => {
  const currentStepValue = currentStep + 1
  return (
    <div className="flex md:hidden mt-2 justify-between">
      <Text className="text-xs text-white">{`Step ${currentStepValue} of ${steps.length}`}</Text>
      {currentStep < steps.length - 1 ? (
        <Text className="text-xs capitalize text-white">Next: {steps[currentStepValue].name.toLowerCase()}</Text>
      ) : (
        <Text className="text-xs capitalize text-white">Review your data</Text>
      )}
    </div>
  )
}

export const WizardForm: React.FC<Props> = ({
  steps,
  name = '',
  handleCallback,
  isSubmitting,
  reviewType = 'company',
}: Props) => {
  const { query, asPath } = useRouter()
  const shouldReduceMotion = useReducedMotion()
  const [bounds, setBounds] = useState([])
  const [currentStep, setCurrentStep] = useState(0)
  const [storageLoaded, setStorageLoaded] = useState(false)
  const [values, setValues] = useState<any | null>(steps)
  const [reviewtype, setType] = useState<string>(reviewType)
  const [reviewName, setReviewName] = useState<string>(name)
  const toggleAbandonedForm = useStore((state) => state.toggleAbandonedForm)
  const resetNotification = useStore((state) => state.resetNotification)

  const loadLocalStorage = () => {
    const formData = JSON.parse(window.localStorage.getItem('gn-form') as string)
    delete formData.pathname
    const valueMap: any[] = Object.values(formData).map((data) => data)
    if (valueMap[0].values.reviewtype) {
      setType(valueMap[0].values.reviewtype)
      setCurrentStep(1)
    }
    setValues(valueMap)
    setStorageLoaded(true)
  }

  useEffect(() => {
    if (query.continue && window.localStorage.getItem('gn-form')) {
      loadLocalStorage()
    } else {
      setStorageLoaded(true)
    }
  }, [query])

  const handleSubmit = (data: any) => {
    toggleAbandonedForm(false)
    resetNotification()
    if (data != null) {
      let trackedValues = values

      if (data.bounds) {
        setBounds(data.bounds)
      }

      if (reviewtype === 'company' && data.name) {
        setReviewName(data.name)
      } else if (data.city) {
        setReviewName(formatAddress(data))
      }

      const dataVal = [...trackedValues]
      dataVal[currentStep].values = data
      setValues(dataVal)
      if (data.reviewtype) {
        trackedValues = steps.map((step) => ({ ...step, values: {} }))
        setType(data.reviewtype)
      } else {
        localStorage.setItem('gn-form', JSON.stringify({ ...dataVal, pathname: asPath }))
      }
    }

    if (currentStep < steps.length) {
      setCurrentStep(currentStep + 1)
      window.scrollTo(0, 0)
    }
  }

  if (!storageLoaded) {
    return (
      <div className="text-center py-60">
        <Spinner />
      </div>
    )
  }

  const handleSubmitCallback = () => {
    handleCallback(values)
    localStorage.removeItem('gn-form')
  }

  const visitSection = (sectionID: number) => {
    window.scrollTo(0, 0)
    setCurrentStep(sectionID)
  }

  const initialAnimation = shouldReduceMotion ? { opacity: 0 } : { opacity: 0, y: 5 }

  const fields = values.map((step, i) => {
    switch (step.name) {
      case 'UNIT':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <UnitStep state={step.values} submitCallback={handleSubmit} />
          </motion.div>
        )
      case 'ADDRESS':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <AddressStep state={step.values} submitCallback={handleSubmit} category={reviewtype} />
          </motion.div>
        )
      case 'RENTALADDRESS':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <RentAddressStep state={step.values} submitCallback={handleSubmit} />
          </motion.div>
        )
      case 'TYPE':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <TypeStep submitCallback={handleSubmit} />
          </motion.div>
        )
      case 'INTRO':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <RentIntro submitCallback={handleSubmit} />
          </motion.div>
        )
      case 'REVIEW':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <ReviewStep
              bounds={bounds}
              name={reviewName}
              state={step.values}
              submitCallback={handleSubmit}
              category={reviewtype}
            />
          </motion.div>
        )
      case 'PRICES':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <PriceStep name={reviewName} state={step.values} submitCallback={handleSubmit} category={reviewtype} />
          </motion.div>
        )
      case 'LANDLORD':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <LandlordStep name={reviewName} state={step.values} submitCallback={handleSubmit} category={reviewtype} />
          </motion.div>
        )
      case 'DETAILS':
        return (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            key={i}
            transition={{ duration: 0.5, type: 'tween', ease: 'easeInOut' }}
          >
            <DetailsStep name={reviewName} state={step.values} submitCallback={handleSubmit} category={reviewtype} />
          </motion.div>
        )
      default:
        return null
    }
  })

  const handleBackButton = () => {
    if (
      currentStep === 1 &&
      steps[0].name === 'TYPE' &&
      confirm('Are you sure you want to go back? You will lose your current changes.')
    ) {
      setCurrentStep(0)
      setValues(steps)
    } else {
      setCurrentStep(currentStep - 1)
    }
  }

  return (
    <div className="pt-6 lg:pt-24 pb-16">
      {currentStep !== 0 ? (
        <div className="mb-10">
          <button onClick={handleBackButton} className="text-sm flex items-baseline border-b border-darker font-oakes">
            <BiArrowBack size="15" className="mr-1 relative top-0.5" /> Back to
            <span className="capitalize ml-1 inline-block">
              {currentStep === steps.length ? 'review form' : steps[currentStep - 1].display}
            </span>
          </button>
        </div>
      ) : null}
      <AnimateSharedLayout>
        {currentStep === steps.length ? (
          <motion.div
            initial={initialAnimation}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4 }}
          >
            <ReviewSection
              name={reviewName}
              isSubmitting={isSubmitting}
              type={reviewtype}
              state={values}
              submitCallback={handleSubmitCallback}
              navigateToSection={visitSection}
            />
          </motion.div>
        ) : (
          fields[currentStep]
        )}
      </AnimateSharedLayout>
      <div className="sticky lg:fixed bottom-3 lg:bottom-2 left-0 lg:w-1/2 lg:left-1/4 z-[7999]">
        <div className="w-full col-span-12 lg:col-span-10 md:col-start-2 rounded-lg bg-darker py-4 lg:pt-3 lg:pb-4 px-6 shadow-md border border-darkbg lg:shadow-lg">
          {currentStep !== 0 ? (
            <div className="mb-3">
              <button
                onClick={handleBackButton}
                className="text-xs flex items-baseline border-b border-darker font-oakes text-white"
              >
                <BiArrowBack size="15" className="mr-1 relative top-0.5" /> Back to
                <span className="capitalize ml-1 inline-block">
                  {currentStep === steps.length ? 'review' : steps[currentStep - 1].display}
                </span>
              </button>
            </div>
          ) : null}
          <div className="w-full border rounded-xl shadow-inner bg-darker border-smoke-900">
            <div
              className="h-2 bg-gradient-to-r from-violet-300 lg:from-violet-200 to-violet lg:to-violet-400 rounded-xl transition-all rounded-full"
              style={{ width: `${((currentStep + 1) / (steps.length + 1)) * 100}%` }}
            />
          </div>
          {displayMobileSteps(currentStep, steps)}
          <div className="text-right mt-0 hidden md:flex">
            {steps.map((step, index) => (
              <div key={step.name} className="flex-1">
                <button
                  className={clsx(
                    'text-xs transition-all capitalize focus:outline-none font-oakes',
                    index > currentStep && 'text-smoke-400 cursor-default',
                    index < currentStep && 'text-smoke-200',
                    index === currentStep && 'font-semibold text-white',
                  )}
                  onClick={() => setCurrentStep(index)}
                  disabled={index > currentStep}
                >
                  {step.display}
                </button>
              </div>
            ))}
            <div className="flex-1 mt-1.5">
              <Text
                className={clsx(
                  'text-xs transition-all capitalize text-smoke-400 font-oakes',
                  currentStep === steps.length && 'font-semibold !text-white',
                )}
              >
                Confirm
              </Text>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
