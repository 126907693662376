import { format } from 'date-fns'
import clsx from 'clsx'
import { useController } from 'react-hook-form'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  name: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  min?: string
  max?: string
  isRequired?: boolean
  variant?: 'sm' | 'md'
  className?: string
  defaultValue?: any
  control?: any
  rules?: any
}

export const DateInput: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  isDisabled,
  helpText,
  isRequired,
  min,
  max,
  defaultValue,
  control,
  rules,
  className,
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const id = name.replace(/\s/g, '') + '-hint'

  const classNames = clsx(
    'appearance-none',
    'border',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'rounded-md',
    'placeholder-smoke-500',
    'focus:outline-none focus:ring-1',
    value && 'has-value',
    invalid
      ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600'
      : 'border-smoke-500 focus:ring-darker focus:ring-2',
    isDisabled && 'bg-smoke-200 border-opacity-20',
  )

  return (
    <div
      role="group"
      className={clsx(className, isDisabled && 'opacity-40')}
      title={isDisabled ? 'Field is disabled' : ''}
    >
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={isRequired}
        hideLabel={hideLabel}
        className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-darker')}
      />
      <div className="relative flex-1 mb-1 rounded-xl">
        <input
          className={classNames}
          onChange={onChange}
          ref={ref}
          type="date"
          name={name}
          id={name}
          disabled={isDisabled}
          value={value}
          min={min}
          max={max}
          autoComplete="false"
          autoCorrect="false"
          pattern="^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$"
          aria-disabled={isDisabled}
          aria-invalid={invalid}
          aria-required={isRequired}
          aria-describedby={helpText && id}
          placeholder="yyyy-MM-dd"
        />
      </div>
      {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
      {!!error?.message && (
        <div role="alert">
          <HelpText id={id} isError>
            {error.message}
          </HelpText>
        </div>
      )}
    </div>
  )
}

DateInput.displayName = 'DateInput'
