/* eslint-disable react/no-unescaped-entities */
import React from 'react'
import Image from 'next/image'

import { Heading } from '../../Heading'
import { Text } from '../../Text'
import { TypeCard } from '../components/TypeCard'
import { Button } from 'components/Button'

interface Props {
  submitCallback: (...args: any) => void
}

export const RentIntro = ({ submitCallback }: Props) => (
  <div className="grid grid-cols-12 pb-10 lg:pb-10">
    <div className="col-span-12 lg:col-span-10 lg:col-start-2">
      <header className="mb-5 md:mb-6">
        <Heading as="h1" className="text-4xl lg:text-6xl text-blue-600 mb-1 !font-semibold">
          Add new rental.
        </Heading>
      </header>
      <Heading as="h2" className="!font-semibold text-2xl">
        Why post your rental on Good Neighbour?
      </Heading>
      <Text>
        Good Neighbour is free. We will host your listing for one month and provide renters additional details about the
        unit that others don't.
      </Text>
      <Button onClick={() => submitCallback({})}>Continue</Button>
    </div>
  </div>
)
