import React, { useState } from 'react'
import { useForm } from 'react-hook-form'

import { Heading, TextArea } from 'components'
import { PriceField } from '../components/PriceField'

interface Props {
  submitCallback: (...args: any) => void
  state: any
  category?: string
  name: string
}

export const LandlordStep = ({ submitCallback, state, category, name }: Props) => {
  const [landlordResponsive, setLandlordResponsive] = useState<boolean | null>(
    typeof state.landlordResponsive === 'boolean' ? state.landlordResponsive : null,
  )

  const [wellMaintained, setWellMaintained] = useState<boolean | null>(
    typeof state.wellMaintained === 'boolean' ? state.wellMaintained : null,
  )

  const [landlordLanguages, setLandlordLanguages] = useState<boolean | null>(
    typeof state.landlordLanguages === 'boolean' ? state.landlordLanguages : null,
  )

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      landlordResponsiveStory: state.landlordResponsiveStory || '',
      wellMaintainedStory: state.wellMaintainedStory || '',
      landlordLanguagesStory: state.landlordLanguagesStory || '',
    },
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(async ({ landlordResponsiveStory, wellMaintainedStory, landlordLanguagesStory }) => {
    const payload = {
      landlordResponsiveStory,
      wellMaintainedStory,
      landlordResponsive,
      wellMaintained,
      landlordLanguages,
      landlordLanguagesStory,
    }

    submitCallback(payload)
  })

  return (
    <div className="grid grid-cols-12 pb-6 lg:pb-10">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2">
        <header className="mb-4 md:mb-12">
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            New {category === 'building' ? 'rental' : 'property management'} review.
          </Heading>
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {name}
          </Heading>
        </header>
      </div>

      <div className="col-span-12 lg:col-span-8 lg:col-start-3">
        <form className="w-full" onSubmit={onSubmit}>
          <Heading as="h2" className="text-2xl md:text-3xl">
            Tell us about the landlord.
          </Heading>

          <PriceField
            icon="responsive"
            alt="A bell"
            title="Was the landlord responsive to requests?"
            verifier={landlordResponsive}
            setCallback={setLandlordResponsive}
            theme="violet"
          >
            <TextArea
              height="h-36"
              control={control}
              name="landlordResponsiveStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about the landlords responsiveness."
            ></TextArea>
          </PriceField>

          <PriceField
            icon="languages"
            alt="A bell"
            title="Does the landlord support other languages?"
            verifier={landlordLanguages}
            setCallback={setLandlordLanguages}
            theme="yellow"
          >
            <TextArea
              height="h-36"
              control={control}
              name="landlordLanguagesStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about the languages the landlord supported."
            ></TextArea>
          </PriceField>

          <PriceField
            icon="maintain"
            alt="A hammer"
            title="Is the property well maintained?"
            verifier={wellMaintained}
            setCallback={setWellMaintained}
            theme="green"
          >
            <TextArea
              height="h-36"
              control={control}
              name="wellMaintainedStory"
              rules={{
                maxLength: {
                  value: 2500,
                  message: 'Content must be a maximum of 2500 characters',
                },
              }}
              helpText="Do not include any personal information of yourself or others."
              label="Tell us more about the property and the maintainance."
            ></TextArea>
          </PriceField>

          <div className="flex justify-end mt-16" data-fullstory="Images">
            <button
              data-fullstory="Prices"
              type="submit"
              className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
