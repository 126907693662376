import clsx from 'clsx'
interface Props {
  children: React.ReactNode
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p'
  className?: string
}

export const Heading: React.FC<Props> = ({ as = 'h1', children, className = '' }: Props) => {
  const CustomTag = `${as}` as keyof JSX.IntrinsicElements

  const cn = clsx('font-oakes', 'tracking-tight', 'font-medium', className)

  return <CustomTag className={cn}>{children}</CustomTag>
}
