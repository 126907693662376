import { memo } from 'react'
import { Marker } from 'react-map-gl'
import { motion } from 'framer-motion'
import clsx from 'clsx'
import { IListing } from 'lib/utils/interfaces'
import { FaStethoscope, FaTree, FaPills, FaTools, FaGlasses, FaBook } from 'react-icons/fa'
import { MdLocalGroceryStore, MdPets, MdLocalLaundryService } from 'react-icons/md'
import { IoMdPin } from 'react-icons/io'
import { AiFillBank } from 'react-icons/ai'
import { BiHomeHeart } from 'react-icons/bi'

import formatAddress from 'lib/utils/formatAddress'

const SIZE = 25

interface PinProps {
  lat: number
  lng: number
  rating?: number
  index?: number
  onClick?: any
  category?: string
  title?: string
  type?: string
}

interface PinsProps {
  data: IListing[]
  onClick?: any
}

const variants = {
  visible: (i) => ({
    y: 0,
    transition: {
      delay: i * 0.2,
    },
  }),
  hidden: (i) => ({
    y: 50,
    transition: {
      delay: i * 0.2,
    },
  }),
}

export const AniPin: React.FC<PinProps> = memo(({ lat, lng, onClick = () => null, index }: PinProps) => (
  <Marker latitude={lat} longitude={lng}>
    <div className="text-smoke-600 border-full w-16 h-16">
      <motion.svg
        className="text-blue-500 cursor-pointer fill-current stroke-0"
        width={SIZE}
        height={SIZE}
        viewBox="0 0 16 16"
        onClick={onClick}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        custom={index}
        animate="visible"
        variants={variants}
      >
        <circle cx="8" cy="8" r="8" className="fill-blue-600" />
      </motion.svg>
    </div>
  </Marker>
))

AniPin.displayName = 'AniPin'

export const Pins: React.FC<PinsProps> = ({ data, onClick = () => null }: PinsProps) => (
  <>
    {data
      .filter((pin) => pin?.latitude && pin?.longitude)
      .map((pin, i) => (
        <Pin
          index={i}
          key={i}
          title={pin.category === 'company' ? pin.name : formatAddress(pin)}
          category={pin.category}
          rating={pin.overallRating || pin.rating}
          lat={pin.latitude}
          lng={pin.longitude}
          onClick={() => {
            onClick(pin.latitude, pin.longitude, pin.id)
          }}
        />
      ))}
  </>
)

Pins.displayName = 'Pins'

function formatType(type: string) {
  switch (type) {
    case 'grocery' || 'discountstore':
      return <MdLocalGroceryStore size="14" />
    case 'chiropractors' ||
      'acupunture' ||
      'health' ||
      'physicaltherapy' ||
      'generaldentistry' ||
      'lifecoach' ||
      'c_and_mh' ||
      'surgeons' ||
      'oralsurgeons':
      return <FaStethoscope size="14" />
    case 'pharmacy':
      return <FaPills size="14" />
    case 'vet':
      return <MdPets size="14" />
    case 'dryclean' || 'laundry' || 'laundryservices' || 'sewingalterations':
      return <MdLocalLaundryService size="14" />
    case 'homedecor' || 'hardware' || 'locksmiths':
      return <FaTools size="14" />
    case 'galleries':
      return <BiHomeHeart size="14" />
    case 'daycamps' || 'parks' || 'recreation':
      return <FaTree size="14" />
    case 'libraries' || 'childcare' || 'privatetutors':
      return <FaBook size="14" />
    case 'banks':
      return <AiFillBank size="14" />
    case 'optometrists':
      return <FaGlasses size="14" />
    default:
      return <IoMdPin size="14" />
  }
}

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export const Pin: React.FC<PinProps> = memo(
  ({ title, lat, lng, rating, type, category, onClick = () => null }: PinProps) => (
    <Marker latitude={lat} longitude={lng} captureClick={false}>
      <motion.button
        className={clsx(
          'rounded-full flex items-center justify-center shadow-lg',
          type ? 'bg-smoke-100 border border-smoke-300 w-8 h-8 text-darker' : 'bg-darker w-4 h-4 text-white',
        )}
        key={`${lat}-${lng}`}
        onClick={onClick}
        title={title}
      >
        {type ? formatType(type) : null}
      </motion.button>
    </Marker>
  ),
)

Pin.displayName = 'Pin'

// Important for perf: the markers never change, avoid rerender when the map viewport changes
export const UserPin: React.FC<PinProps> = memo(({ lat, lng }: PinProps) => (
  <Marker latitude={lat} longitude={lng} captureClick={false}>
    <svg className="text-green-500 fill-current cursor-pointer stroke-0" height="32" viewBox="0 0 32 32">
      <motion.circle cx="16" cy="15" r="8" className="" />
      <motion.circle
        animate={{
          opacity: [0.15, 0.4, 0.15],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
        }}
        cx="16"
        cy="16"
        r="16"
      />
    </svg>
  </Marker>
))

UserPin.displayName = 'UserPin'
