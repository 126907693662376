/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { MdModeEdit } from 'react-icons/md'
import { format } from 'date-fns'
import clsx from 'clsx'
import { useForm } from 'react-hook-form'
import { StaticMap as Map } from 'react-map-gl'
import { Pin } from 'components/Pin'
import { ReactSVG } from 'react-svg'

const MAPBOX_TOKEN = process.env.NEXT_PUBLIC_MAPBOX_TOKEN

import { StepType } from './index'
import { ImageContainer, Checkbox, Rating, Text, Heading } from 'components'

import { useAuthenticate } from 'lib/hooks/useGetAuthenticate'
import { SignupSection } from './components/SignupSection'

interface Props {
  state: StepType[]
  type: string
  navigateToSection: (...args: any) => void
  submitCallback: (...args: any) => void
  isSubmitting: boolean
  name?: string
}

export const ReviewSection = ({ type, state, navigateToSection, submitCallback, isSubmitting, name }: Props) => {
  const [showValidate, setShowValidate] = useState(false)
  const { loggedIn } = useAuthenticate()
  const { handleSubmit, control } = useForm({
    defaultValues: {
      agree: false,
      email: '',
    },
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(({ agree }) => {
    if (loggedIn && agree) {
      submitCallback()
    } else if (agree && !loggedIn) {
      window.scrollTo(0, 0)
      setShowValidate(true)
    }
  })

  const sections = state.map((value, i) => {
    switch (value.name) {
      case 'UNIT':
        return (
          <div className="pt-6 mt-6" key={value.name}>
            <header className="flex justify-between">
              <button onClick={() => navigateToSection(i)} className="text-smoke-600">
                <MdModeEdit />
              </button>
            </header>
            <div className="space-y">
              <Text className="text-sm font-semibold">{value.values.unitnumber}</Text>
              <Text className="text-sm font-semibold capitalize">{value.values.type}</Text>
            </div>
          </div>
        )
      case 'ADDRESS':
        return (
          <div className="pt-6 mt-6" key={value.name}>
            {type === 'company' ? (
              <div className="pt-6 mt-6" key={value.name}>
                <div className="space-y">
                  {type === 'company' && value.values.email ? (
                    <Text className="text-sm">{value.values.email}</Text>
                  ) : null}
                  {type === 'company' && value.values.website ? (
                    <Text className="text-sm">{value.values.website}</Text>
                  ) : null}
                  {type === 'company' && value.values.phoneNumber ? (
                    <Text className="text-sm">{value.values.phoneNumber}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}
            {value.values.latitude && value.values.longitude ? (
              <figure className="shadow-xl block rounded-xl overflow-hidden">
                <Map
                  width="100%"
                  height="300px"
                  mapboxApiAccessToken={MAPBOX_TOKEN}
                  latitude={value.values.latitude}
                  longitude={value.values.longitude}
                  mapStyle={`mapbox://styles/mapbox/streets-v11?optimize=true`}
                  zoom={15}
                  pitch={35}
                  attributionControl={false}
                  reuseMaps={true}
                >
                  <Pin lat={value.values.latitude} lng={value.values.longitude} />
                </Map>
              </figure>
            ) : null}
          </div>
        )

      case 'PRICES':
        const prices = value.values
        return (
          <div className={clsx('flex-wrap flex')} key={value.name}>
            {typeof prices.gasIncluded === 'boolean' ? (
              <div className="flex items-center mr-16 my-6">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/gas.svg" />
                </div>
                <Text className="tracking-tight font-oakes ml-4">
                  {prices.gasIncluded ? 'Gas Included' : `Gas approx $${prices.gasAvgPrice} per month`}
                </Text>
              </div>
            ) : null}

            {typeof prices.waterIncluded === 'boolean' ? (
              <div className="flex items-center mr-16 my-6">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/water.svg" />
                </div>
                <Text className="tracking-tight font-oakes ml-4">
                  {prices.waterIncluded ? 'Water Included' : `Water approx $${prices.waterAvgPrice} per month`}
                </Text>
              </div>
            ) : null}

            {typeof prices.hydroIncluded === 'boolean' ? (
              <div className="flex items-center mr-16 my-6">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/hydro.svg" />
                </div>
                <Text className="tracking-tight font-oakes ml-4">
                  {prices.hydroIncluded
                    ? 'Electricity Included'
                    : `Electricity approx $${prices.hydroAvgPrice} per month`}
                </Text>
              </div>
            ) : null}
            {typeof prices.parkingAvailable === 'boolean' ? (
              <div className="flex items-center mr-16 my-6">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/parking.svg" />
                </div>
                <Text className="tracking-tight font-oakes ml-4">
                  {prices.parkingAvailable ? 'Parking Available' : 'Parking not available'}
                  {prices.parkingAvgPrice ? `: approx $${prices.parkingAvgPrice} per month` : null}
                </Text>
              </div>
            ) : null}
            {typeof prices.laundryIncluded === 'boolean' ? (
              <div className="flex items-center mr-16 my-6">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/laundry.svg" />
                </div>

                <div className="ml-4">
                  <Text className="tracking-tight font-oakes">
                    {prices.laundryIncluded ? 'Laundry Available' : 'Laundry not available'}
                  </Text>

                  {prices.laundryIncluded ? (
                    <>
                      <Text className="text-sm tracking-tighter">
                        {prices.laundryAvgPrice
                          ? `Washing Machine $${prices.laundryAvgPrice} per load`
                          : `Washing Machine included`}
                      </Text>
                      <Text className="text-sm tracking-tighter">
                        {prices.dryerAvgPrice ? `Dryer $${prices.dryerAvgPrice} per load` : `Dryer included`}
                      </Text>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        )
      case 'DETAILS':
        const details = value.values
        return (
          <div className="space-y-12" key={value.name}>
            {typeof details.dishwasherAvailable === 'boolean' ? (
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/dishwasher.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.dishwasherAvailable ? 'Dishwasher Included' : 'No dishwasher'}
                  </Text>
                </div>
              </div>
            ) : null}

            {typeof details.airConditioning === 'boolean' ? (
              <div className="flex items-center">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/airconditioner.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.airConditioning ? 'Air Conditioning Included' : 'No air conditioning'}
                  </Text>
                </div>
              </div>
            ) : null}

            {typeof details.petsWelcome === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/pets.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.petsWelcome ? 'Pets are welcome' : 'No pets welcome'}
                  </Text>
                  {details.petsWelcomeStory ? <Text className="text-sm">{details.petsWelcomeStory}</Text> : null}
                </div>
              </div>
            ) : null}

            {typeof details.accessibility === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/accessibility.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.accessibility ? 'Accessibility Friendly' : 'Not Accessiblity Friendly'}
                  </Text>
                  {details.accessibilityStory ? <Text className="text-sm">{details.accessibilityStory}</Text> : null}
                </div>
              </div>
            ) : null}

            {typeof details.noisyNeighbours === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/noisy.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.noisyNeighbours ? 'Neighbours are noisy.' : 'No noisy neighbours here.'}
                  </Text>
                  {details.noisyNeighboursStory ? (
                    <Text className="text-sm">{details.noisyNeighboursStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof details.pests === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/pests.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {details.pests
                      ? 'There were pests, insects or unwanted animals'
                      : 'No pests, insects or unwanted animals'}
                  </Text>
                  {details.pestsStory ? <Text className="text-sm">{details.pestsStory}</Text> : null}
                </div>
              </div>
            ) : null}
          </div>
        )
      case 'LANDLORD':
        const landlord = value.values
        return (
          <div className="space-y-12" key={value.name}>
            {typeof landlord.landlordResponsive === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/responsive.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {landlord.landlordResponsive
                      ? 'Landlord was responsive to requests'
                      : 'Landlord was not responsive to requests'}
                  </Text>
                  {landlord.landlordResponsiveStory ? (
                    <Text className="text-sm">{landlord.landlordResponsiveStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof landlord.landlordLanguages === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/languages.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {landlord.landlordLanguages
                      ? 'Landlord supports other languages'
                      : 'Landlord does not support other languages'}
                  </Text>
                  {landlord.landlordLanguagesStory ? (
                    <Text className="text-sm">{landlord.landlordLanguagesStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof landlord.wellMaintained === 'boolean' ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/maintain.svg" />
                </div>
                <div className="ml-4 flex-1">
                  <Text className="tracking-tight font-oakes">
                    {landlord.wellMaintained ? 'Property was well maintained' : 'Property was not well maintained'}
                  </Text>
                  {landlord.wellMaintainedStory ? (
                    <Text className="text-sm">{landlord.wellMaintainedStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        )
      case 'REVIEW':
        const values = value.values
        return (
          <div key={value.name}>
            <Rating value={values.rating} className="text-yellow-400" />
            <Text className="text-smoke-600 text-xs">
              {values.tenancyState && values.tenancyEnd
                ? `${format(new Date(values.tenancyStart), 'MMMM do, y')} - ${format(
                    new Date(values.tenancyEnd),
                    'MMMM do, y',
                  )}`
                : null}
            </Text>
            <div className="my-4">
              <Text className="text-sm">{values.content}</Text>
            </div>
            {values.photos.length ? <ImageContainer files={values.photos} /> : null}
            {type === 'building' ? (
              <Text className="text-darker text-xl font-semibold tracking-tighter">
                ${values.rentPrice} monthly rent
              </Text>
            ) : null}
          </div>
        )
      case 'IMAGES':
        return (
          <div key={value.name}>
            <header className="flex justify-between">
              <Heading as="h3" className="text-lg text-green-600">
                Images
              </Heading>
              <button onClick={() => navigateToSection(i)} className="text-smoke-600">
                <MdModeEdit />
              </button>
            </header>
            {value.values.length ? (
              <div className="flex items-start mb-8 flex-wrap mt-6">
                <ImageContainer files={value.values} />
              </div>
            ) : (
              'No images.'
            )}
          </div>
        )
    }
  })

  return (
    <div className="grid grid-cols-12 pb-16">
      <div className="col-span-12">
        <header>
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            {showValidate ? 'Sign in to post your review of' : 'Confirm your review.'}
          </Heading>
        </header>
        <div className="my-4">
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {name}
          </Heading>
        </div>
        {!showValidate ? (
          <div className="col-span-12">
            <form onSubmit={onSubmit}>
              <div className="space-y-6">{sections}</div>
              <div className="mt-6 pt-6">
                <Checkbox
                  label={
                    <>
                      By submitting this review, you agree that the content contains views, experiences and opinions
                      that are solely those of the individual who has submitted them. The review information provided
                      will be public and accessible to all users of Good Neighbour and will be subject to moderation.
                      You agree that the content provided adheres to the Good Neighbour{' '}
                      <a href="/content-policy" target="_blank">
                        Review Terms
                      </a>
                      . Your personal and account details will be kept anonymous.
                    </>
                  }
                  rules={{
                    required: true,
                  }}
                  control={control}
                  name="agree"
                />
              </div>

              <button
                type="submit"
                className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm mt-6"
              >
                Submit
              </button>
            </form>
          </div>
        ) : (
          <>
            <SignupSection submitCallback={submitCallback} />
            <button onClick={() => setShowValidate(false)} className="underline text-left text-sm mb-4">
              Return to confirmation.
            </button>
          </>
        )}
      </div>
    </div>
  )
}
