import create from 'zustand'

type State = {
  searchCoords: {
    latitude: number
    longitude: number
    zoom: number
    bounds: { maxLat: number; minLat: number; maxLng: number; minLng: number }
  } | null
  redirectPath: string
  userId: string
  userEmail: string
  abandonedForm: boolean
  menuOpen: boolean
  overlayOpen: boolean
  isLoggedIn: boolean
  searchParams: string
  showCookieBanner: boolean
  userLocation: { latitude: number; longitude: number; zoom: number } | null
  notification: {
    type: 'error' | 'success' | 'info' | null
    message: string
    success?: any
    cancel?: any
    timeout?: boolean
  }
  setNotification: (message: {
    type: 'error' | 'success' | 'info' | null
    message: string
    success?: any
    cancel?: any
    timeout?: boolean
  }) => void
  resetNotification: () => void
  toggleLoggedIn: () => void
  setUserLocation: (data: { latitude: number; longitude: number; zoom: number }) => void
  toggleCookieBanner: (state: boolean) => void
  toggleAbandonedForm: (state: boolean) => void
  toggleMenu: () => void
  toggleOverlay: (state: boolean) => void
  setSearchCoords: (coords: any) => void
  setRedirectPath: (path: string) => void
  setSearchParams: (path: string) => void
  setUserId: (id: string) => void
  setEmail: (id: string) => void
}

export const useStore = create<State>((set, get) => ({
  redirectPath: '',
  searchParams: '',
  userId: '',
  userEmail: '',
  menuOpen: false,
  overlayOpen: false,
  isLoggedIn: false,
  abandonedForm: false,
  showCookieBanner: false,
  userLocation: null,
  searchCoords: null,
  notification: {
    type: null,
    message: '',
  },
  setNotification: ({ type, message, success, cancel, timeout = true }) =>
    set(() => {
      return { notification: { type, message, success, cancel, timeout } }
    }),
  resetNotification: () =>
    set(() => {
      return {
        notification: {
          type: null,
          message: '',
        },
      }
    }),
  toggleMenu: () =>
    set(() => {
      const menuState = get().menuOpen
      return { menuOpen: !menuState }
    }),
  toggleAbandonedForm: (state) =>
    set(() => {
      return { abandonedForm: state }
    }),
  toggleOverlay: (state) =>
    set(() => {
      return { overlayOpen: state }
    }),
  setSearchCoords: (coords) => set(() => ({ searchCoords: coords })),
  setUserLocation: (data) =>
    set(() => {
      return { userLocation: { latitude: data.latitude, longitude: data.longitude, zoom: data.zoom } }
    }),
  toggleCookieBanner: (state) =>
    set(() => {
      return { showCookieBanner: state }
    }),
  toggleLoggedIn: () =>
    set(() => {
      return { isLoggedIn: get().userId.length > 0 }
    }),
  setRedirectPath: (path) => set(() => ({ redirectPath: path })),
  setSearchParams: (value) => set(() => ({ searchParams: value })),
  setUserId: (id) => set(() => ({ userId: id })),
  setEmail: (id) => set(() => ({ userEmail: id })),
}))
