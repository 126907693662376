import { useForm } from 'react-hook-form'

import { uppercaseEachWord } from 'lib/utils/stringFormatting'

import { Input, Select } from 'components'

interface Props {
  submitCallback: (...args: any) => void
  state: any
}

export const UnitStep = ({ submitCallback, state }: Props) => {
  const { handleSubmit, control } = useForm({
    defaultValues: {
      type: state.type || 'condo',
      unitnumber: state.unitnumber || '',
    },
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(async ({ unitnumber, type }) => {
    submitCallback({
      unitnumber: uppercaseEachWord(unitnumber),
      type,
    })
  })

  return (
    <div className="flex justify-center">
      <div className="w-full md:w-3/4 mt-8">
        <form className="w-full" onSubmit={onSubmit}>
          <div className="space-y-6 md:space-y-0 md:space-x-6 flex flex-col md:flex-row">
            <Select label="Type of Unit" name="type" control={control} className="w-full lg:w-1/2">
              <option value="condo">Condo</option>
              <option value="room">Room</option>
              <option value="apartment">Apartment</option>
            </Select>

            <Input
              type="text"
              label="Unit Number"
              name="unitnumber"
              control={control}
              placeholder="Unit Number"
              className="w-full lg:w-1/2"
            />
          </div>
          <div className="flex justify-end mt-6">
            <button
              data-fullstory="Review"
              type="submit"
              className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
