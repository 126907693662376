import { fetcher } from '../fetcher'

export const deleteReview = async (data: any) => {
  try {
    const deletedReviewForListing = await fetcher(`user/review/${data.reviewId}`, {
      options: {
        method: 'delete',
      },
    })

    return deletedReviewForListing
  } catch (err) {
    throw err
  }
}
