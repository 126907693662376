import clsx from 'clsx'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { CustomLink } from '../utils/CustomLink'
export interface Props {
  children: React.ReactNode
  href: string
  withArrow?: boolean
  className?: string
}

export const ButtonLink: React.FC<Props> = ({ children, href, withArrow, className }: Props) => (
  <CustomLink
    href={href}
    className={clsx(
      'no-underline border-b border-violet-500 text-current hover:border-transparent inline-flex items-center py-1 transition duration-500 font-normal ease-in-out motion-reduce:transition-none font-heading',
      className,
    )}
  >
    {children}{' '}
    {withArrow && (
      <span className="inline-block ml-2">
        <HiArrowNarrowRight size="14" />
      </span>
    )}
  </CustomLink>
)

ButtonLink.displayName = 'Button'
