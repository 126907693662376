import { FacebookProvider, Login } from 'react-facebook'
import { ReactSVG } from 'react-svg'
import { useRouter } from 'next/router'
import { useState } from 'react'

import { login } from 'lib/auth'
import { fetcher } from 'lib/fetcher'
import { useStore } from 'lib/state'

const FACEBOOK_CLIENT_ID = process.env.NEXT_PUBLIC_FACEBOOK_CLIENT_ID || ''

export interface Props {
  label: string
  handleCallback: (id: string, token: string, email: string) => void
}

export const FacebookAuth: React.FC<Props> = ({ label, handleCallback }: Props) => {
  const handleLogin = async (fbData) => {
    const {
      profile: { first_name, email, last_name },
      tokenDetail: { accessToken },
    } = fbData
    try {
      const fbAuth = await fetcher(`auth/facebook`, {
        options: {
          body: {
            first_name,
            email,
            last_name,
            token: accessToken,
          },
          method: 'post',
        },
      })
      handleCallback(fbAuth.data.id, fbAuth.data.token, email)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <FacebookProvider appId={FACEBOOK_CLIENT_ID}>
      <Login scope="email" onCompleted={handleLogin}>
        {({ loading, handleClick, error, data }) => (
          <>
            <button
              onClick={handleClick}
              className="font-oakes bg-blue-600 text-white border border-smoke-300 rounded-full py-3.5 px-12 md:w-auto transition-shadow text-sm
          duration-500 ease-in-out motion-reduce:transition-none flex items-center mt-4 justify-center tracking-wide no-underline hover:bg-opacity-90 shadow-lg hover:shadow-md text-center relative"
            >
              <ReactSVG src="/svg/facebook.svg" className="h-6 w-6 block mr-3 relative -top-0.5" />
              {label}
            </button>
            {loading ? (
              <div role="presentation" aria-hidden="true" className="inline-block relative top-1 ml-2">
                <div
                  className="inline-block w-3 h-3 rounded-full animate-spin border-2 border-solid animate-spin"
                  style={{ borderColor: 'rgba(255,255,255,0.4)', borderTopColor: '#ffffff' }}
                  aria-label="Loading"
                  role="status"
                />
              </div>
            ) : null}
          </>
        )}
      </Login>
    </FacebookProvider>
  )
}

FacebookAuth.displayName = 'FacebookAuth'
