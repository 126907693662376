import Router from 'next/router'
import cookie from 'js-cookie'
import nextCookie from 'next-cookies'

export const TOKEN_NAME = 'gn-profile'
export const REDIRECT_NAME = 'gn-redirect'
export type TOKEN_CONTENT = { id: string }

export const auth = (ctx: any) => {
  const data = nextCookie(ctx)
  const token = data[TOKEN_NAME]

  const redirect = ctx.req.url

  if (ctx.req && !token) {
    cookie.remove(TOKEN_NAME)
    ctx.res.writeHead(302, { Location: redirect ? `/login?redirect=${redirect}` : '/login' })
    ctx.res.end()
    return null
  } else if (!token) {
    Router.push(redirect ? `/login?redirect=${redirect}` : '/login')
    return null
  } else {
    return token
  }
}

export const login = ({ token, id }: { token: string; id: string }) => {
  localStorage.setItem(TOKEN_NAME, id)
  cookie.set(TOKEN_NAME, token, { expires: 0.5 })
}

export const logout = () => {
  cookie.remove(TOKEN_NAME)
  localStorage.removeItem(TOKEN_NAME)
}
