/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link'
import clsx from 'clsx'

export interface Props {
  children: any
  href: string
  passHref?: boolean
  decorated?: boolean
  className?: string
  title?: string
  [key: string]: any
}

export const CustomLink: React.FC<Props> = ({
  href,
  children,
  passHref = false,
  decorated = false,
  title = '',
  className,
  ...otherProps
}: Props) => (
  <Link href={href} passHref={passHref} {...otherProps}>
    <a title={title} className={clsx(decorated && 'no-underline', 'inline-block font-oakes', className)}>
      {children}
    </a>
  </Link>
)
