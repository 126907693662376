import clsx from 'clsx'
import { HiCheckCircle } from 'react-icons/hi'
import { MdError } from 'react-icons/md'
import { IoMdClose } from 'react-icons/io'
import { motion, AnimatePresence } from 'framer-motion'

import { useStore } from '../../lib/state'
import { Text } from '../Text'
import { useEffect } from 'react'

export const Toast = () => {
  const notification = useStore((state) => state.notification)
  const resetNotification = useStore((state) => state.resetNotification)

  useEffect(() => {
    if (notification.timeout) {
      setTimeout(() => {
        resetNotification()
      }, 5000)
    }
  }, [notification.type])

  const handleCancel = () => {
    notification.cancel()
    resetNotification()
  }

  const handleSuccess = () => {
    notification.success()
    resetNotification()
  }

  return (
    <div className="fixed right-2 left-2 sm:left-auto top-28 lg:top-28 z-8000">
      <AnimatePresence initial={false}>
        {notification.type ? (
          <motion.div
            initial={{ opacity: 0, y: 50, scale: 0.3 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
            role="alert"
            className={clsx(
              'py-4 pl-5 pr-12 rounded-xl shadow-md border flex items-start w-full lg:w-auto max-w-md bg-white relative',
              notification.type === 'success' && 'bg-white',
              notification.type === 'error' && 'bg-pinker-600',
            )}
          >
            <div>
              <Text
                className={clsx(
                  'leading-relaxed font-oakes',
                  notification.type === 'error' ? 'text-white' : 'text-darkbg',
                )}
              >
                {notification.message}
              </Text>
              {notification.success || notification.cancel ? (
                <div className="flex space-x-2 mt-4">
                  {notification.success ? (
                    <button
                      onClick={handleSuccess}
                      className="text-sm bg-darkbg rounded-full pt-2 pb-1 px-3 hover:bg-white hover:text-darkbg border border-darkbg font-oakes text-white transition-all"
                    >
                      Continue
                    </button>
                  ) : null}
                  {notification.cancel ? (
                    <button onClick={handleCancel} className="text-sm font-oakes text-darkbg relative top-0.5">
                      Cancel
                    </button>
                  ) : null}
                </div>
              ) : null}
            </div>
            <button className="absolute top-2 right-2" title="Close Notification" onClick={resetNotification}>
              <IoMdClose size="24" />
            </button>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  )
}
