/* eslint-disable react/no-unescaped-entities */
import { useState, useRef, useEffect } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { RiSearchLine } from 'react-icons/ri'
import clsx from 'clsx'

import { provinces, states } from 'lib/utils/regions'
import { uppercaseEachWord } from 'lib/utils/stringFormatting'
import { geocode } from 'lib/geocode'

import { Text, Heading, Input, PhoneInput, Select } from 'components'

interface Props {
  submitCallback: (...args: any) => void
  state: any
  category?: string
}

const us = new RegExp('^\\d{5}(-{0,1}\\d{4})?$')
const ca = new RegExp(/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i)

export const RentAddressStep = ({ submitCallback, state }: Props) => {
  const node = useRef<HTMLDivElement>(null)
  const [bounds, setBounds] = useState([])
  const [resultsLoading, setResultsLoading] = useState(false)
  const [searchResults, setSearchResults] = useState<any | null>()
  const [addressResult, setAddressResult] = useState<string | null>(
    state.city
      ? `${state.streetnumber} ${state.streetname}, ${state.neighbourhood}, ${state.city}, ${state.region} ${state.country} ${state.postalzip}`
      : '',
  )
  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      country: state.country || 'Canada',
      region: state.country || 'ON',
      type: state.type || 'house',
      unitnumber: state.unitnumber || '',
      bedrooms: state.bedrooms || 1,
      bathrooms: state.bathrooms || 1,
      streetnumber: state.streetnumber || '',
      streetname: state.streetname || '',
      neighbourhood: state.neighbourhood || '',
      city: state.city || '',
      postalzip: state.postalzip || '',
      searchValue: '',
      latitude: state.latitude || '',
      longitude: state.longitude || '',
    },
    mode: 'onSubmit',
  })

  const resetForm = () => {
    reset()
    setAddressResult(null)
  }

  const onSubmit = handleSubmit(
    async ({
      unitnumber,
      streetnumber,
      streetname,
      neighbourhood,
      bedrooms,
      bathrooms,
      city,
      country,
      region,
      postalzip,
      type,
    }) => {
      const payload = {
        streetnumber: streetnumber.trim(),
        streetname: uppercaseEachWord(streetname.trim()),
        city: uppercaseEachWord(city.trim()),
        neighbourhood: uppercaseEachWord(neighbourhood),
        country: 'Canada',
        region,
        postalzip: postalzip.toUpperCase(),
        bounds,
        type,
        bedrooms,
        bathrooms,
      }
      if (unitnumber) {
        payload['unitnumber'] = uppercaseEachWord(unitnumber)
      }
      submitCallback(payload)
    },
  )

  const country = useWatch({
    control,
    name: 'country',
    defaultValue: 'Canada',
  })
  const type = useWatch({
    control,
    name: 'type',
    defaultValue: 'house',
  })

  const handleSearchClick = async (e) => {
    e.preventDefault()
    setResultsLoading(true)
    const value = getValues('searchValue')
    if (value.length) {
      try {
        const res = await geocode(value)
        setResultsLoading(false)
        setSearchResults(res)
      } catch (e) {
        setResultsLoading(false)
        console.log(e)
      }
    }
  }

  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setSearchResults(null)
  }

  useEffect(() => {
    if (searchResults?.length) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchResults])

  const setResultValues = (address, bounding_box) => {
    const streetnumber = address.house_number
    const streetname = address.road
    const neighbourhood = address.neighbourhood || address.suburb || ''
    const city = address.city || address.village || address.town
    const country = address.country_code === 'us' ? 'USA' : 'Canada'
    const postalzip = address.postcode
    let region

    if (address.country_code === 'us') {
      const stateValue = Object.entries(states).find((state) => state[1] === address.state)
      if (stateValue) {
        setValue('region', stateValue[0])
        region = stateValue[0]
      }
    } else {
      const provinceValue = Object.entries(provinces).find((province) => province[1] === address.state)
      if (provinceValue) {
        setValue('region', provinceValue[0])
        region = provinceValue[0]
      }
    }

    setAddressResult(`${streetnumber} ${streetname}, ${city}, ${region}, ${neighbourhood}, ${country}, ${postalzip}`)
    setValue('streetnumber', streetnumber)
    setValue('streetname', streetname)
    setValue('neighbourhood', neighbourhood)
    setValue('city', city)
    setValue('country', country)
    setValue('postalzip', postalzip)
    setBounds(bounding_box)
    setSearchResults(null)
  }

  const requiresUnitNumber = type !== 'house' && type !== 'building'
  const isCanada = country === 'Canada'
  return (
    <div className="grid grid-cols-12 pb-12 lg:pb-10">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2">
        <header className="mb-0 md:mb-4">
          <Heading as="h1" className="text-4xl lg:text-6xl text-blue-600 mb-1 !font-semibold">
            Add new rental.
          </Heading>
        </header>
        <div className="mb-2 md:mb-8">
          <Heading as="h3" className="text-lg sm:text-2xl md:text-4xl md:mb-2">
            What's the unit address?
          </Heading>
        </div>

        <div className="w-full">
          <form onSubmit={handleSearchClick}>
            <div className="relative flex" ref={node}>
              <Input
                type="search"
                label="Find an address"
                name="searchValue"
                control={control}
                placeholder="123 Main Street"
                button={
                  <button
                    type="submit"
                    className="rounded-full p-4 text-white bg-gradient-to-b to-blue-500 from-violet-700 shadow-md"
                  >
                    {resultsLoading ? (
                      <div
                        className="w-4 h-4 rounded-full border-2 border-solid animate-spin"
                        style={{ borderColor: 'rgba(255,255,255,0.4)', borderTopColor: '#fff' }}
                        aria-label="Loading"
                        role="status"
                      />
                    ) : (
                      <RiSearchLine size="21" />
                    )}
                  </button>
                }
                className="w-full"
              />
              {searchResults?.length ? (
                <div className="absolute rounded-md shadow-lg border border-smoke-100 z-30 top-20 w-full max-h-48 overflow-hidden">
                  <ul className="bg-white divide-solid divide-y top-20 w-full max-h-48 overflow-y-scroll">
                    {searchResults.map((result) => {
                      const address = result.address

                      if (address?.house_number && address?.road && address?.city) {
                        const addressString = `${address.house_number} ${address.road}, ${
                          address.city || address.town || address.village
                        }, ${address.state}, ${address.country}`
                        return (
                          <li key={result.place_id}>
                            <button
                              onClick={() => setResultValues(result.address, result?.bounding_box)}
                              className="px-3 block w-full text-left hover:text-blue-600 text-sm py-3"
                            >
                              {addressString}
                            </button>
                          </li>
                        )
                      } else {
                        return null
                      }
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          </form>

          <form className="w-full" onSubmit={onSubmit}>
            <>
              <div className="block border-t py-8 space-y-6 mt-8">
                <div className="grid lg:grid-cols-12 gap-4 sm:gap-8">
                  <Input
                    type="text"
                    label="Street Number"
                    name="streetnumber"
                    control={control}
                    rules={{
                      required: 'Street number is required',
                    }}
                    placeholder="Street Number"
                    className="col-span-12 lg:col-span-2"
                  />

                  <Input
                    type="text"
                    label="Street Name"
                    name="streetname"
                    control={control}
                    rules={{
                      required: 'Street name is required',
                    }}
                    placeholder="Street Name"
                    className="col-span-12 lg:col-span-5"
                  />
                  <Input
                    type="text"
                    label="City"
                    name="city"
                    control={control}
                    rules={{ required: 'City is required' }}
                    placeholder="City"
                    className="col-span-12 lg:col-span-5"
                  />
                </div>

                <div className="grid lg:grid-cols-12 gap-4 sm:gap-8">
                  <Input
                    type="text"
                    label="Neighbourhood"
                    name="neighbourhood"
                    control={control}
                    className="col-span-12 lg:col-span-3"
                    rules={{
                      min: {
                        value: 4,
                        message: 'Minimum of 4 characters',
                      },
                    }}
                    placeholder="Neighbourhood"
                  />
                  <Select
                    label={isCanada ? 'Province' : 'State'}
                    name="region"
                    control={control}
                    className="col-span-12 lg:col-span-3"
                  >
                    {Object.entries(isCanada ? provinces : states).map(([optionValue, optionName]) => (
                      <option key={optionValue} value={optionValue}>
                        {optionName}
                      </option>
                    ))}
                  </Select>
                  {/* <Select label="Country" name="country" control={control} className="col-span-12 lg:col-span-3">
                    <option value="Canada">Canada</option>
                    <option value="USA">USA</option>
                  </Select> */}
                  <Input
                    className="col-span-12 lg:col-span-3"
                    type="text"
                    label={isCanada ? 'Postal Code' : 'Zip Code'}
                    name="postalzip"
                    control={control}
                    rules={{
                      required: isCanada ? 'Postal Code is required' : 'Zip Code is required',
                      pattern: {
                        value: isCanada ? ca : us,
                        message: `${isCanada ? 'Postal Code' : 'Zip Code'} is not valid`,
                      },
                    }}
                    placeholder={isCanada ? 'Postal Code' : 'Zip Code'}
                  />
                </div>
              </div>

              <div>
                <div className="grid border-t py-6 lg:grid-cols-12 gap-4 sm:gap-8">
                  <Input
                    isDisabled={type === 'house' || type === 'building'}
                    type="text"
                    label="Unit Number or location"
                    name="unitnumber"
                    className="col-span-12 lg:col-span-3"
                    control={control}
                    rules={{
                      required: {
                        value: requiresUnitNumber,
                        message: 'Unit number is required',
                      },
                    }}
                    placeholder="Unit Number"
                  />
                  <Select label="Bedrooms" name="bedrooms" control={control} className="col-span-12 lg:col-span-2">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3+">3+</option>
                  </Select>

                  <Select label="Bathrooms" name="bathrooms" control={control} className="col-span-12 lg:col-span-2">
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3+">3+</option>
                  </Select>
                  <Select label="Type of Listing" name="type" control={control} className="col-span-12 lg:col-span-2">
                    <option value="apartment">Apartment</option>
                    <option value="house">House</option>
                    <option value="condo">Condo</option>
                    <option value="room">Room</option>
                    <option value="building">Building</option>
                  </Select>
                </div>
              </div>
            </>

            <div className="flex space-x-4 justify-end">
              <button onClick={resetForm} className="text-smoke-600 text-sm">
                Reset
              </button>
              <button
                data-fullstory="Review"
                type="submit"
                className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
              >
                Create Review
                <HiArrowNarrowRight size="14" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
