import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Heading, NumberInput } from 'components'
import { PriceField } from '../components/PriceField'

interface Props {
  submitCallback: (...args: any) => void
  state: any
  category?: string
  name: string
}

export const PriceStep = ({ submitCallback, state, name, category }: Props) => {
  const [gasIncluded, setGasIncluded] = useState<boolean | null>(
    typeof state.gasIncluded === 'boolean' ? state.gasIncluded : null,
  )
  const [waterIncluded, setWaterIncluded] = useState<boolean | null>(
    typeof state.waterIncluded === 'boolean' ? state.waterIncluded : null,
  )
  const [hydroIncluded, setHydroIncluded] = useState<boolean | null>(
    typeof state.hydroIncluded === 'boolean' ? state.hydroIncluded : null,
  )
  const [parkingAvailable, setParkingAvailable] = useState<boolean | null>(
    typeof state.parkingAvailable === 'boolean' ? state.parkingAvailable : null,
  )
  const [laundryIncluded, setLaundryIncluded] = useState<boolean | null>(
    typeof state.laundryIncluded === 'boolean' ? state.laundryIncluded : null,
  )
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      laundryAvgPrice: state.laundryAvgPrice || 0,
      dryerAvgPrice: state.dryerAvgPrice || 0,
      gasAvgPrice: state.gasAvgPrice || 0,
      waterAvgPrice: state.waterAvgPrice || 0,
      hydroAvgPrice: state.hydroAvgPrice || 0,
      parkingAvgPrice: state.parkingAvgPrice || 0,
    },
    mode: 'onSubmit',
  })

  const onSubmit = handleSubmit(
    async ({ laundryAvgPrice, dryerAvgPrice, gasAvgPrice, waterAvgPrice, hydroAvgPrice, parkingAvgPrice }) => {
      const payload = {
        laundryAvgPrice,
        dryerAvgPrice,
        gasAvgPrice,
        waterAvgPrice,
        hydroAvgPrice,
        parkingAvgPrice,
        parkingAvailable,
        gasIncluded,
        waterIncluded,
        hydroIncluded,
        laundryIncluded,
      }

      submitCallback(payload)
    },
  )

  return (
    <div className="grid grid-cols-12 pb-6 lg:pb-10">
      <div className="col-span-12 lg:col-span-10 lg:col-start-2">
        <header className="mb-4 md:mb-12">
          <Heading as="h1" className="font-oakes !font-semibold text-3xl sm:text-4xl lg:text-5xl text-darker">
            New {category === 'building' ? 'rental' : 'property management'} review.
          </Heading>
          <Heading as="h3" className="text-2xl md:text-3xl text-violet">
            {name}
          </Heading>
        </header>
      </div>

      <div className="col-span-12 lg:col-span-8 lg:col-start-3">
        <form className="w-full" onSubmit={onSubmit}>
          <Heading as="h2" className="text-2xl md:text-3xl">
            Tell us about the costs.
          </Heading>

          {category === 'building' ? (
            <PriceField
              icon="gas"
              alt="Gas in a chemistry set"
              title="Is gas included?"
              verifier={gasIncluded}
              setCallback={setGasIncluded}
              theme="violet"
            >
              <NumberInput
                isDisabled={!!gasIncluded}
                className="w-full"
                name="gasAvgPrice"
                label="Average monthly gas bill"
                rules={{
                  min: {
                    value: 0,
                    message: 'Minimum price is $0',
                  },
                  max: {
                    value: 1000,
                    message: 'Maximum price is $1000',
                  },
                  valueAsNumber: true,
                }}
                helpText="Rough Estimate is fine."
                control={control}
              />
            </PriceField>
          ) : null}

          {category === 'building' ? (
            <PriceField
              icon="water"
              alt="Cup of water"
              title="Is water included?"
              verifier={waterIncluded}
              setCallback={setWaterIncluded}
              theme="yellow"
            >
              <NumberInput
                isDisabled={!!waterIncluded}
                className="w-full"
                name="waterAvgPrice"
                label="Average monthly water bill"
                rules={{
                  min: {
                    value: 0,
                    message: 'Minimum price is $0',
                  },
                  max: {
                    value: 100,
                    message: 'Maximum price is $100',
                  },
                  valueAsNumber: true,
                }}
                helpText="Rough Estimate is fine."
                control={control}
              />
            </PriceField>
          ) : null}

          {category === 'building' ? (
            <PriceField
              icon="hydro"
              alt="A battery icon"
              title="Is electricity included?"
              verifier={hydroIncluded}
              setCallback={setHydroIncluded}
              theme="green"
            >
              <NumberInput
                isDisabled={!!hydroIncluded}
                className="w-full"
                name="hydroAvgPrice"
                label="Average monthly electricity bill"
                rules={{
                  min: {
                    value: 0,
                    message: 'Minimum price is $0',
                  },
                  max: {
                    value: 100,
                    message: 'Maximum price is $100',
                  },
                  valueAsNumber: true,
                }}
                helpText="Rough Estimate is fine."
                control={control}
              />
            </PriceField>
          ) : null}

          <PriceField
            icon="parking"
            alt="A toy car"
            title="Is parking available with the unit?"
            verifier={parkingAvailable}
            setCallback={setParkingAvailable}
            theme="violet"
          >
            <NumberInput
              isDisabled={parkingAvailable === false}
              className="w-full"
              name="parkingAvgPrice"
              label="Average monthly parking cost"
              rules={{
                min: {
                  value: 0,
                  message: 'Minimum price is $0',
                },
                max: {
                  value: 1000,
                  message: 'Maximum price is $1000',
                },
                valueAsNumber: true,
              }}
              control={control}
            />
          </PriceField>

          <PriceField
            icon="laundry"
            alt="A sweater"
            title="Is laundry available with the unit?"
            verifier={laundryIncluded}
            setCallback={setLaundryIncluded}
            theme="yellow"
          >
            <NumberInput
              isDisabled={laundryIncluded === false}
              className="w-full mb-6"
              name="laundryAvgPrice"
              label="Price of washing machine per load"
              rules={{
                max: {
                  value: 100,
                  message: 'Maximum price is $100',
                },
                valueAsNumber: true,
              }}
              helpText="Leave at 0 if free."
              control={control}
            />

            <NumberInput
              isDisabled={laundryIncluded === false}
              className="w-full"
              name="dryerAvgPrice"
              label="Price of dryer per load"
              rules={{
                max: {
                  value: 100,
                  message: 'Maximum price is $100',
                },
                valueAsNumber: true,
              }}
              helpText="Leave at 0 if free."
              control={control}
            />
          </PriceField>

          <div className="flex justify-end mt-16" data-fullstory="Images">
            <button
              data-fullstory="Confirm"
              type="submit"
              className="font-oakes tracking-wide bg-darkbg shadow-md text-white pt-3 pb-2 px-6 rounded-full flex items-center text-sm"
            >
              Next
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}
