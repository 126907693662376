import { Plugin } from '@uppy/core'
import ImageKit from 'imagekit-javascript'

const IMAGEKIT_AUTH_ENDPOINT = process.env.NEXT_PUBLIC_IMAGEKIT_AUTH_ENDPOINT
const IMAGEKIT_PUBLIC_KEY = process.env.NEXT_PUBLIC_IMAGEKIT_PUBLIC_KEY
const API_ENDPOINT = process.env.NEXT_PUBLIC_API_ENDPOINT
const IMAGEKIT_ENDPOINT = process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT

const imagekit = new ImageKit({
  publicKey: IMAGEKIT_PUBLIC_KEY,
  urlEndpoint: IMAGEKIT_ENDPOINT,
  authenticationEndpoint: `${API_ENDPOINT}${IMAGEKIT_AUTH_ENDPOINT}`,
})

class ImageKitPlugin extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts)
    this.id = opts.id || 'MyPlugin'
    this.type = 'ImageKitUpload'
    this.folder = opts.folder || '/p'
    this.uploadFiles = this.uploadFiles.bind(this)
  }

  folder: null

  async uploadFiles(fileIDs) {
    const uploadGroup = fileIDs.map(async (fileID) => {
      const file = this.uppy.getFile(fileID)
      this.uppy.emit('upload-started', file)
      return new Promise((resolve, reject) => {
        imagekit.upload({ file: file.data, fileName: file.meta.name, folder: this.folder }, (err: any, result) => {
          if (err) {
            console.log(err)
            this.uppy.emit('upload-error', file)
            reject(file)
          }
          resolve({ result, file })
        })
      })
    })

    const emituploadFilesCompleteForAll = (fileIDs) => {
      fileIDs.forEach((file) => {
        this.uppy.emit('upload-success', file.file, {
          status: 200,
          body: file.result,
          uploadURL: file.result.url,
        })
      })
    }

    return Promise.all(uploadGroup).then(emituploadFilesCompleteForAll)
  }

  install() {
    this.uppy.addUploader(this.uploadFiles)
  }

  uninstall() {
    this.uppy.removeUploader(this.uploadFiles)
  }
}

export default ImageKitPlugin
