import clsx from 'clsx'
import { RiSearchLine } from 'react-icons/ri'
import { useEffect, useState } from 'react'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  type: 'text' | 'email' | 'search' | 'password' | 'tel' | 'hidden' | 'url'
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  pattern?: string
  value?: any
  button?: JSX.Element[] | JSX.Element
  className?: string
  defaultValue?: any
  onChange?: any
  control?: any
  rules?: any
  sentValue?: string | null
}

export const StockInput: React.FC<Props> = ({
  type = 'text',
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  isRequired,
  sentValue = '',
  pattern,
  button,
  className,
  onChange,
}: Props) => {
  const [value, setValue] = useState('')

  useEffect(() => {
    if (sentValue) {
      setValue(sentValue)
    }
  }, [sentValue])

  const handleChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }

  const id = name.replace(/\s/g, '') + '-hint'

  const classNames = clsx(
    'appearance-none',
    'border',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'rounded-md',
    'placeholder-smoke-600',
    'border-smoke-600',
    'focus:outline-none focus:ring-darker',
    type === 'search' && 'pl-8',
    isDisabled && 'bg-smoke-100 border-smoke-700',
  )

  return (
    <div
      role="group"
      className={clsx(className, isDisabled && 'opacity-40')}
      title={isDisabled ? 'Field is disabled' : ''}
    >
      {type !== 'hidden' ? (
        <Label label={label} name={name} isRequired={isRequired} hideLabel={hideLabel} className="mb-2" />
      ) : null}
      <div className="flex">
        <div className="relative flex-1 mb-1 rounded-xl">
          <input
            className={classNames}
            onChange={handleChange}
            type={type}
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={isDisabled}
            value={value}
            pattern={pattern}
            autoComplete="false"
            autoCorrect="false"
            aria-disabled={isDisabled}
            aria-required={isRequired}
            aria-describedby={helpText && id}
          />
          {type === 'search' ? (
            <div role="presentation" aria-hidden="true" className="absolute top-1/3 left-3">
              <RiSearchLine />
            </div>
          ) : null}
        </div>
        {button && <div className="ml-3">{button}</div>}
      </div>
      {helpText && <HelpText id={id}>{helpText}</HelpText>}
    </div>
  )
}

StockInput.displayName = 'Input'
