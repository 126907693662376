import cookie from 'js-cookie'
import { useEffect } from 'react'

import { useStore } from '../../lib/state'
import { ButtonLink } from '../ButtonLink'
import { Text } from '../Text'

export const CookieBanner: React.FC = () => {
  const showCookieBanner = useStore((state) => state.showCookieBanner)
  const toggleCookieBanner = useStore((state) => state.toggleCookieBanner)

  useEffect(() => {
    const existingCookie = cookie.get('__gn_accepted_cookies')

    if (!existingCookie) {
      toggleCookieBanner(true)
    }
  })

  const setCookie = () => {
    cookie.set('__gn_accepted_cookies', true, { expires: 365 })
    toggleCookieBanner(false)
  }

  if (!showCookieBanner) {
    return null
  }

  return (
    <div className="fixed bottom-6 left-4 sm:left-auto right-4 md:right-6 bg-white shadow-lg rounded-lg text-darker z-9000 overflow-hidden py-6 px-6 border border-smoke-200">
      <div className="mb-4">
        <Text className="text-lg">
          <span role="img" aria-label="Cookie emoji" className="inline-block mr-1">
            🍪
          </span>
          We use cookies for good things.
        </Text>
        <ButtonLink href="/cookies" className="text-xs">
          Read our Cookie Policy.
        </ButtonLink>
      </div>
      <button
        className="rounded-full text-center hover:bg-white hover:ring-2 hover:ring-darker hover:text-darker bg-darker pt-2.5 pb-2 px-4 text-white font-oakes text-sm"
        onClick={setCookie}
      >
        I love cookies.
      </button>
    </div>
  )
}

CookieBanner.displayName = 'CookieBanner'
