import clsx from 'clsx'
import { useState } from 'react'
import { useController } from 'react-hook-form'

interface Props {
  label: React.ReactNode
  disabled?: boolean
  name: string
  control?: any
  rules?: any
}

export const Checkbox: React.FC<Props> = ({ label, disabled, name, control, rules }: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid },
  } = useController({
    name,
    control,
    rules,
  })
  const [isChecked, setIsChecked] = useState(value || false)

  const handleCheck = () => {
    const checked = !isChecked
    setIsChecked(checked)
    onChange(checked)
  }

  return (
    <label className="inline-flex items-start space-x-3">
      <input
        ref={ref}
        type="checkbox"
        className={clsx(
          'form-tick appearance-none h-6 w-6 border rounded-md bg-white checked:bg-darkbg checked:border-transparent focus:outline-none flex-shrink-0',
          invalid ? 'border-pinker-600' : 'border-smoke-500',
          disabled && 'bg-smoke-200 border-opacity-20',
        )}
        onChange={handleCheck}
        checked={isChecked}
        name={name}
        disabled={disabled}
        aria-invalid={invalid}
        aria-disabled={disabled}
      />
      <span
        className={clsx(
          'text-sm md:text-sm flex-grow relative top-0.5',
          invalid ? 'text-pinker-600' : 'text-smoke-800',
          disabled && 'opacity-40',
        )}
      >
        {label}
      </span>
    </label>
  )
}

Checkbox.displayName = 'Checkbox'
