import ReactStars from 'react-rating-stars-component'

import clsx from 'clsx'

interface Props {
  label: string
  hideLabel?: boolean
  className?: string
  value?: number | string
  onChange?: any
  ref?: any
  error?: any
  size?: number
}

export const RatingInput = ({ className, hideLabel, label, value = 0, onChange, error, size = 28 }: Props) => {
  const classNames = clsx(
    hideLabel && 'sr-only',
    'my-1',
    'block',
    'tracking-tighter font-oakes',
    className,
    error ? 'text-red-500' : 'text-current',
  )

  return (
    <div role="application" className={clsx(className, 'rating-input')}>
      <label className={classNames}>{label}</label>
      <ReactStars
        activeColor="#FFBE0B"
        color="#c9c9c9"
        count={5}
        value={Number(value)}
        onChange={onChange}
        a11y={true}
        size={size}
        isHalf={false}
      />
    </div>
  )
}

RatingInput.displayName = 'RatingInput'
