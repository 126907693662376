import clsx from 'clsx'
import { HiArrowNarrowRight } from 'react-icons/hi'

export interface Props {
  children: React.ReactNode
  onClick?: (...args: any[]) => any
  color?: 'primary' | 'secondary' | 'error' | 'warning'
  disabled?: boolean
  variant?: 'solid' | 'link' | 'outline'
  withArrow?: boolean
  icon?: React.ReactNode
  type?: 'button' | 'submit' | 'reset' | undefined
  className?: string
  isLoading?: boolean
  loadingText?: string
}

export const Button: React.FC<Props> = ({
  children,
  onClick,
  disabled,
  variant = 'solid',
  withArrow,
  type,
  icon,
  className = '',
  isLoading,
  loadingText,
}: Props) => {
  const cn = clsx(
    'font-oakes',
    'transition',
    'duration-500',
    'ease-in-out',
    'motion-reduce:transition-none',
    variant !== 'link' &&
      `py-4 px-6 md:px-8 hover:bg-opacity-70 rounded-full disabled:opacity-90 disabled:cursor-wait shadow-md`,
    variant === 'link' &&
      'no-underline border-b font-normal border-blue-600 text-current hover:border-transparent inline-flex items-center py-2 transition duration-500 ease-in-out motion-reduce:transition-none rounded-none text-sm hover:opacity-70 shadow-lg',
    variant === 'solid' && `text-white bg-darkbg hover:opacity-80`,
    variant === 'outline' && `text-pacific-900 border-pacific-900 border-2`,
    className,
  )

  return (
    <button className={cn} onClick={onClick} disabled={disabled || isLoading} aria-disabled={disabled} type={type}>
      {isLoading && loadingText ? loadingText : children}
      {isLoading ? (
        <div role="presentation" aria-hidden="true" className="inline-block relative top-1 ml-2">
          <div
            className="inline-block w-3 h-3 rounded-full animate-spin border-2 border-solid animate-spin"
            style={{ borderColor: 'rgba(255,255,255,0.4)', borderTopColor: '#ffffff' }}
            aria-label="Loading"
            role="status"
          />
        </div>
      ) : null}
      {withArrow && !icon && !isLoading && (
        <div className="inline-block ml-2 relative top-0.5">
          <HiArrowNarrowRight size="14" />
        </div>
      )}
      {icon && !withArrow && !isLoading && (
        <div className="inline-block ml-2 relative top-0.5 text-white" role="presentation">
          {icon}
        </div>
      )}
    </button>
  )
}

Button.displayName = 'Button'
