/* eslint-disable jsx-a11y/anchor-is-valid */
import Image from 'next/image'
import Link from 'next/link'
import { IListing, IReview } from 'lib/utils/interfaces'
import formatAddress from 'lib/utils/formatAddress'
import { RiErrorWarningLine } from 'react-icons/ri'
import { ReactSVG } from 'react-svg'

import { Text, Rating } from 'components'

interface ResultCardProps {
  listing: {
    type: string
    id: string
    category: string
    name: string
    reviewId?: string
    address?: string
    overallRating: number
    reviews: IReview[] | null
    conciseReview: string
    childListings: IListing[]
    unitnumber: string | null
    streetnumber: string
    streetname: string
    city: string
    region: string
    country: string
    postalzip: string
    averagePrice?: number
  }
}

export const SearchResultsCard = ({ listing }: ResultCardProps) => (
  <li key={listing.id} className="text-sm  py-5 relative border-smoke-200 border-t">
    <div className="flex">
      <div className="text-blue-400 mr-2 w-4 relative">
        <ReactSVG src="/svg/icons/pin.svg" />
      </div>
      <div className="text-sm flex-1">
        <Text className="text-xs text-smoke-600 mb-1 font-oakes">
          {listing.category === 'company' || listing.type === 'landlord' || listing.type === 'property_management'
            ? 'Property Management'
            : 'Rental Unit'}
        </Text>
        <div className="mb-1">
          {listing.category === 'company' || listing.type === 'landlord' || listing.type === 'property_management' ? (
            <Link href={`management/${listing.id}`} passHref>
              <a className="font-semibold block text-sm no-underline hover:underline overflow-ellipsis font-oakes">
                {listing?.type && listing?.type !== 'landlord' && listing.city
                  ? `${listing.name} - ${listing.city} `
                  : listing.name}
              </a>
            </Link>
          ) : (
            <Link href={`listing/${listing.id}`} passHref>
              <a className="font-semibold block text-sm no-underline hover:underline overflow-ellipsis font-oakes">
                {listing.address || formatAddress(listing)}
              </a>
            </Link>
          )}

          <div className="flex items-center mt-1 mb-3">
            {listing.overallRating ? (
              <Rating
                value={listing.overallRating}
                type="Review"
                canPluralize={true}
                count={listing.reviews?.length}
                className="text-yellow-500 mr-1.5"
              />
            ) : null}
            {listing.averagePrice ? (
              <Text className="text-xs text-smoke-600 font-oakes mt-1">Average Rent: ${listing.averagePrice}</Text>
            ) : null}
          </div>
        </div>
        {listing.conciseReview ? <Text className="text-sm text-smoke-600">{listing.conciseReview}...</Text> : null}
        {listing.childListings?.length ? (
          <div className="lg:col-span-5 flex mt-3 ml-3 lg:mt-2 lg:ml-0">
            <Text className="text-smoke-800 text-sm font-semibold">Units:</Text>
            <ul className="flex flex-wrap ml-2">
              {listing.childListings.map((child) => (
                <li key={child.id} className="mr-3 text-sm">
                  <Link href={`listing/${child.id}`} passHref>
                    <a className="text-blue-600 no-underline font-semibold">{child.unitnumber}</a>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <Link
          href={
            listing.reviewId
              ? `/contact?type=review_issue&rid=${listing.reviewId}`
              : listing.category === 'company' || listing.type === 'landlord' || listing.type === 'property_management'
              ? `/contact?type=company_error&mid=${listing.id}`
              : `/contact?type=listing_error&lid=${listing.id}`
          }
          passHref
        >
          <a
            className="no-underline text-xs text-smoke-500 hover:text-smoke-400 py-1 inline-block absolute right-2 top-2"
            title="Report Review"
          >
            <RiErrorWarningLine size="15" />
            <span className="sr-only">Report</span>
          </a>
        </Link>
      </div>
    </div>
  </li>
)

SearchResultsCard.displayName = 'SearchResultsCard'
