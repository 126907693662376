import useSWR from 'swr'
import { fetcher } from 'lib/fetcher'

export function useGetLatestReviews({ limit = 10, offset = 0 }: { limit?: number; offset?: number }) {
  const { data, error } = useSWR(`content?limit=${limit}&offset=${offset}`, fetcher)

  return {
    reviews: data?.data || [],
    isLoading: !error && !data,
    isError: error || !data?.success,
  }
}
