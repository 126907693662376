import clsx from 'clsx'

import { Text } from '../Text'

interface Props {
  isError?: boolean
  id: string
  children: JSX.Element[] | JSX.Element | (string | JSX.Element)[] | string
}

export const HelpText: React.FC<Props> = ({ isError, id, children }: Props) => {
  const cx = clsx('mt', 'ml-2')
  return (
    <div className={cx} id={id}>
      <Text className={clsx(isError ? 'text-red-500' : 'text-darker', 'text-xs')}>{children}</Text>
    </div>
  )
}

HelpText.displayName = 'HelpText'
