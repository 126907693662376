import { useForm } from 'react-hook-form'
import MailchimpSubscribe from 'react-mailchimp-subscribe'

import { Heading } from '../Heading'
import { Text } from '../Text'

const url = 'https://usegoodnbr.us1.list-manage.com/subscribe/post?u=4689b4a6307b5713f714d569c&amp;id=3b97d4e500'

export const MailChimpSignup: React.FC = () => {
  const { handleSubmit, register, reset } = useForm()

  return (
    <>
      <Heading as="p" className="!font-semibold mb-1 uppercase !tracking-wider text-violet-200 text-sm ">
        Subscribe to our newsletter.
      </Heading>
      <Text className="text-sm">The latest reviews and stories sent to your inbox.</Text>

      <MailchimpSubscribe
        url={url}
        render={({ subscribe, status, message }) => (
          <form onSubmit={handleSubmit((formData) => subscribe(formData as any))} className="mt-4">
            <div className="block relative">
              <label htmlFor="email" className="text-white text-sm block mb-1 ml-1">
                Email Address:
              </label>
              <div className="flex pr-16">
                <input
                  type="email"
                  {...register('EMAIL', { required: true })}
                  className="border border-1 border-smoke-800 bg-smoke-800 bg-opacity-50 pt-2 pb-1.5 px-2 outline-none rounded-md sm:w-1/2 mr-2"
                />
                <button
                  type="submit"
                  className="text-sm bg-white text-darker pt-2.5 pb-2 px-3 ml-2 font-oakes relative -top-0.5 rounded-full"
                >
                  Subscribe.
                </button>
              </div>
            </div>
            {status === 'success' && (
              <Text className="text-darker font-oakes mt-2 flex">
                <span role="img" aria-label="Celebration Emoji">
                  🎉
                </span>{' '}
                <p
                  className="text-darker font-oakes text-sm ml-3"
                  dangerouslySetInnerHTML={{ __html: message as string }}
                />
              </Text>
            )}
            {status === 'error' && (
              <p
                className="text-white font-oakes text-sm mt-4"
                dangerouslySetInnerHTML={{ __html: message as string }}
              />
            )}
            {status === 'sending' && <Text className="text-white font-oakes mt-4 text-sm">Sending...</Text>}
          </form>
        )}
      />
    </>
  )
}
