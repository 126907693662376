import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { RiSearchLine } from 'react-icons/ri'
import { MdClear } from 'react-icons/md'
import { useController } from 'react-hook-form'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  button?: JSX.Element[] | JSX.Element
  className?: string
  onChange?: any
  defaultValue?: any
  control?: any
  rules?: any
  isLoading?: boolean
  handleClear?: () => any
}

export const SearchInput: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  isRequired,
  button,
  className,
  defaultValue = '',
  isLoading,
  control,
  rules,
  handleClear,
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })
  const id = name.replace(/\s/g, '') + '-hint'
  const [isFocused, setIsFocused] = useState(value ? true : false)

  useEffect(() => {
    if (!!value?.length) {
      setIsFocused(true)
    } else {
      setIsFocused(false)
      if (ref) {
        const refValue = ref as any
        refValue.current?.blur()
      }
    }
  }, [value])

  const classNames = clsx(
    'pr-3',
    'pl-10',
    'pt-5',
    'pb-1',
    'w-full',
    'text-base',
    'bg-white',
    'rounded-xl',
    'appearance-none',
    'border',
    'placeholder-transparent',
    // 'shadow-inner-lg',
    'pr-4',
    'overflow-hidden',
    'focus:outline-none focus:ring-1 focus:ring-blue-600',
    invalid ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600' : 'border-smoke-500 focus:ring-blue-600',
  )

  return (
    <div role="group" className={clsx(className, (isDisabled || isLoading) && 'opacity-80', 'relative')}>
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={isRequired}
        hideLabel={hideLabel}
        className={clsx(
          'absolute whitespace-nowrap overflow-hidden z-10 left-10 transition-all pointer-events-none',
          isFocused ? '-top-0.5 mt-2 text-xs' : 'text-sm',
        )}
      />
      <div className="flex md:flex-row w-full">
        <div className="relative flex-1">
          <input
            className={classNames}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => (!value.length ? setIsFocused(false) : null)}
            ref={ref}
            type="search"
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={isDisabled || isLoading}
            value={value}
            aria-disabled={isDisabled}
            aria-invalid={invalid}
            aria-required={isRequired}
            aria-describedby={helpText && id}
          />
          <div role="presentation" aria-hidden="true" className="absolute top-3.5 left-3">
            <RiSearchLine className="text-lg" size="18" />
          </div>
          {value.length && handleClear ? (
            <button className="absolute right-3 top-4" onClick={handleClear} type="button">
              <span className="sr-only">Remove value</span>
              <MdClear size="18" />
            </button>
          ) : null}
        </div>
        {button && <div className="ml-2 hidden md:block relative top-1">{button}</div>}
      </div>
      {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
      {!!error?.message && (
        <div role="alert">
          <HelpText id={id} isError>
            {error.message}
          </HelpText>
        </div>
      )}
    </div>
  )
}

SearchInput.displayName = 'SearchInput'
