import { GoogleLogin } from 'react-google-login'
import { ReactSVG } from 'react-svg'
import { useState } from 'react'

import { fetcher } from 'lib/fetcher'

const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || ''

export interface Props {
  label: string
  handleCallback: (id: string, token: string, email: string) => void
}

export const GoogleAuth: React.FC<Props> = ({ label, handleCallback }: Props) => {
  const [loading, setLoading] = useState(false)

  const handleLogin = async (googleData) => {
    setLoading(true)
    try {
      const googleAuth = await fetcher(`auth/google`, {
        options: {
          body: {
            token: googleData.tokenId,
          },
          method: 'post',
        },
      })

      handleCallback(googleAuth.data.id, googleAuth.data.token, googleAuth.data.email)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <>
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        onSuccess={handleLogin}
        onFailure={handleLogin}
        cookiePolicy={'single_host_origin'}
        render={(renderProps) => (
          <button
            onClick={renderProps.onClick}
            disabled={renderProps.disabled}
            className="font-oakes bg-white text-darkbg border border-smoke-300 rounded-full py-3.5 px-12 md:w-auto transition-shadow text-sm
        duration-500 ease-in-out motion-reduce:transition-none flex items-center mt-4 justify-center tracking-wide no-underline hover:bg-opacity-90 shadow-lg hover:shadow-md text-center relative"
          >
            <ReactSVG src="/svg/google.svg" className="h-6 w-6 block mr-3 relative -top-0.5" />
            {label}
          </button>
        )}
      />
      {loading ? (
        <div role="presentation" aria-hidden="true" className="inline-block relative top-1 ml-2">
          <div
            className="inline-block w-3 h-3 rounded-full animate-spin border-2 border-solid animate-spin"
            style={{ borderColor: 'rgba(255,255,255,0.4)', borderTopColor: '#ffffff' }}
            aria-label="Loading"
            role="status"
          />
        </div>
      ) : null}
    </>
  )
}

GoogleAuth.displayName = 'GoogleAuth'
