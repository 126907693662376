import { useEffect } from 'react'
import cookie from 'js-cookie'
import clsx from 'clsx'
import { useRouter } from 'next/router'
import { motion, AnimatePresence } from 'framer-motion'

import { useStore } from 'lib/state'
import { TOKEN_NAME, logout, login } from 'lib/auth'

import { Nav } from '../Nav'
import { Footer } from '../Footer'
import { CookieBanner } from '../CookieBanner'
import { Toast } from '../Toast'
import { Text } from '../Text'
import { Container } from '../Container'

export interface Props {
  children: JSX.Element[] | JSX.Element
}

export const SiteLayout: React.FC<Props> = ({ children }: Props) => {
  const { push, pathname } = useRouter()
  const setUserId = useStore((state) => state.setUserId)
  const overlayOpen = useStore((state) => state.overlayOpen)
  const toggleLoggedIn = useStore((state) => state.toggleLoggedIn)
  const toggleAbandonedForm = useStore((state) => state.toggleAbandonedForm)
  const setNotification = useStore((state) => state.setNotification)

  const handleAuthState = () => {
    const token = cookie.get(TOKEN_NAME)
    const userId = localStorage.getItem(TOKEN_NAME)

    if (!token || !userId) {
      logout()
      setUserId('')
    } else {
      login({
        token,
        id: userId,
      })
      setUserId(userId)
      toggleLoggedIn()
    }
  }

  const removeForm = () => {
    window.localStorage.removeItem('gn-form')
    toggleAbandonedForm(false)
  }

  useEffect(() => {
    if (window.localStorage.getItem('gn-form')) {
      const handlePath = JSON.parse(window.localStorage.getItem('gn-form') as string)
      const url = handlePath.pathname.split('?')
      toggleAbandonedForm(true)
      setNotification({
        type: 'success',
        message: `You have an unfinished review. Would you like to continue editing it?`,
        timeout: false,
        cancel: removeForm,
        success: () => push(`${url[0]}?continue=true`),
      })
    }
  }, [])

  useEffect(() => {
    handleAuthState()
  })

  return (
    <div
      className={clsx(
        'flex flex-col justify-between text-darker bg-white relative bg-main_gradient bg-cover w-full md:pt-0',
        pathname !== '/' && 'pt-16',
      )}
    >
      <Nav />
      <Toast />
      <AnimatePresence>
        {overlayOpen ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
            className={clsx(
              'fixed left-0 h-screen w-full z-8000 bg-darker bg-opacity-20 top-0 md:top-10 lg:top-20 backdrop-filter backdrop-blur-sm',
            )}
          />
        ) : null}
      </AnimatePresence>
      <div className="flex-1">{children}</div>
      <Footer />
      <CookieBanner />
    </div>
  )
}

SiteLayout.displayName = 'SiteLayout'
