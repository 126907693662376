import { useEffect, useState, useRef } from 'react'

interface Props {
  min?: number
  max?: number
  minValue?: number
  maxValue?: number
  minValueCallback?: any
  maxValueCallback?: any
}

export const Slider: React.FC<Props> = ({
  min = 0,
  max = 100,
  minValue = 0,
  maxValue = 100,
  minValueCallback = () => null,
  maxValueCallback = () => null,
}: Props) => {
  const [minVal, setMinVal] = useState(minValue)
  const [maxVal, setMaxVal] = useState(maxValue)
  const range = useRef(null)

  useEffect(() => setMinVal(minValue), [minValue])
  useEffect(() => setMaxVal(maxValue), [maxValue])
  useEffect(() => setLeftValue(), [minVal])
  useEffect(() => setRightValue(), [maxVal])

  const getPercent = (value) => Math.round(((value - min) / (max - min)) * 100)

  // Set width of the range to decrease from the left side
  const setLeftValue = () => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxVal)
    const refRange = range as any
    if (refRange.current) {
      refRange.current.style.left = `${minPercent}%`
      refRange.current.style.width = `${maxPercent - minPercent}%`
    }
  }

  // Set width of the range to decrease from the right side
  const setRightValue = () => {
    const minPercent = getPercent(minVal)
    const maxPercent = getPercent(maxVal)
    const refRange = range as any
    if (refRange.current) {
      refRange.current.style.width = `${maxPercent - minPercent}%`
    }
  }

  const handleMinValue = (event) => {
    const value = Math.min(parseInt(event.target.value), maxVal - 1)
    minValueCallback(value)
    setMinVal(value)
  }

  const handleMaxValue = (event) => {
    const value = Math.max(parseInt(event.target.value), minVal + 1)
    maxValueCallback(value)
    setMaxVal(value)
  }

  return (
    <div className="relative">
      <input
        type="range"
        min={min}
        max={max}
        value={minVal}
        onChange={handleMinValue}
        className="appearance-none absolute h-0 w-full outline-none pointer-events-none thumb:appearance-none thumb:pointer-events-auto thumb:relative thumb:z-30 thumb:rounded-full thumb:cursor-pointer thumb:w-4 thumb:h-4 thumb:mt-2 thumb:bg-white thumb:shadow-md thumb:right-0 thumb:border thumb:border-smoke-500 thumb:border-solid focus:thumb:ring-2 focus:thumb:ring-blue-600"
      />
      <input
        type="range"
        min={min}
        max={max}
        value={maxVal}
        onChange={handleMaxValue}
        className="appearance-none absolute h-0 w-full outline-none pointer-events-none thumb:appearance-none thumb:pointer-events-auto thumb:relative thumb:z-30 thumb:rounded-full thumb:cursor-pointer thumb:w-4 thumb:h-4 thumb:mt-2 thumb:bg-white  thumb:shadow-md thumb:right-0 thumb:border thumb:border-smoke-500 thumb:border-solid focus:thumb:ring-2 focus:thumb:ring-blue-600"
      />
      <div className="relative w-full h-1.5">
        <div className="rounded-md h-1.5 absolute w-full bg-smoke-300 z-10" />
        <div className="rounded-md h-1.5 absolute bg-gradient-to-r to-blue-500 from-violet-700 z-20" ref={range} />
      </div>
    </div>
  )
}

Slider.displayName = 'Slider'
