import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useController } from 'react-hook-form'
import InputMask from 'react-input-mask'

import { Label } from '../Form/Label'
import { HelpText } from '../Form/HelpText'

interface Props {
  label: string
  placeholder?: string
  name: string
  hideLabel?: boolean
  isDisabled?: boolean
  helpText?: string
  isRequired?: boolean
  pattern?: string
  value?: any
  button?: JSX.Element[] | JSX.Element
  className?: string
  defaultValue?: any
  control?: any
  rules?: any
}

export const PhoneInput: React.FC<Props> = ({
  label,
  name,
  hideLabel,
  placeholder,
  isDisabled,
  helpText,
  isRequired,
  pattern,
  button,
  className,
  defaultValue = '',
  control,
  rules,
}: Props) => {
  const {
    field: { ref, value, onChange },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  })

  const id = name.replace(/\s/g, '') + '-hint'
  const [isFocused, setIsFocused] = useState(value ? true : false)

  useEffect(() => {
    if (value) {
      setIsFocused(true)
      return
    }
  }, [value])

  const classNames = clsx(
    'appearance-none',
    'border',
    'px-3',
    'pt-3.5',
    'pb-4',
    'w-full',
    'bg-white',
    'shadow-inner',
    'rounded-md',
    'placeholder-smoke-500',
    'focus:outline-none focus:ring-2 focus:ring-darker',
    invalid ? 'border-pinker-600 text-pinker-600 focus:ring-pinker-600' : 'border-smoke-500 focus:ring-darker',
    isDisabled && 'bg-smoke-200 border-opacity-20',
  )

  return (
    <div
      role="group"
      className={clsx(className, isDisabled && 'opacity-40')}
      title={isDisabled ? 'Field is disabled' : ''}
    >
      <Label
        label={label}
        name={name}
        isInvalid={invalid}
        isRequired={isRequired}
        hideLabel={hideLabel}
        className={clsx('mb-2', invalid ? 'text-pinker-600' : 'text-smoke-800')}
      />
      <div className="flex">
        <div className="relative flex-1 mb-1 rounded-xl">
          <InputMask
            className={classNames}
            onChange={onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => (!value ? setIsFocused(false) : null)}
            ref={ref}
            mask="(999)-999-9999"
            maskChar="_"
            type="tel"
            name={name}
            id={name}
            placeholder={placeholder}
            disabled={isDisabled}
            value={value}
            pattern={pattern}
            autoComplete="false"
            autoCorrect="false"
            aria-disabled={isDisabled}
            aria-invalid={invalid}
            aria-required={isRequired}
            aria-describedby={helpText && id}
          />
        </div>
        {button && <div className="ml-3 relative top-1">{button}</div>}
      </div>
      {helpText && !error && <HelpText id={id}>{helpText}</HelpText>}
      {!!error?.message && (
        <div role="alert">
          <HelpText id={id} isError>
            {error.message}
          </HelpText>
        </div>
      )}
    </div>
  )
}

PhoneInput.displayName = 'PhoneInput'
