/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/anchor-is-valid */
import algoliasearch from 'algoliasearch'
import { useState, useRef, useEffect } from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import Image from 'next/image'
import { RiSearchLine } from 'react-icons/ri'
import { MdClear } from 'react-icons/md'
import { motion, AnimatePresence } from 'framer-motion'
import { WebMercatorViewport } from 'react-map-gl'
import { ReactSVG } from 'react-svg'

import { Text } from 'components'
import { useStore } from 'lib/state'
import { Label } from '../Form/Label'
import { useDebouncedCallback } from 'lib/hooks/useDebounce'
import { mapbox_geocode } from 'lib/utils/mapboxGeocode'
import { useWindowLock } from 'lib/hooks/useWindowLock'

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP as string,
  process.env.NEXT_PUBLIC_ALGOLIA_KEY as string,
)

const makeCoords = (feature: any) => {
  if (feature.bbox) {
    const vp = new WebMercatorViewport({
      width: 600,
      height: 800,
    })

    const coordinates = vp.fitBounds([
      [Number(feature.bbox[0]), Number(feature.bbox[1])],
      [Number(feature.bbox[2]), Number(feature.bbox[3])],
    ])

    return {
      latitude: feature.center[1],
      longitude: feature.center[0],
      zoom: coordinates.zoom,
      bounds: { maxLat: feature.bbox[3], minLat: feature.bbox[1], maxLng: feature.bbox[2], minLng: feature.bbox[0] },
    }
  } else {
    const vp = new WebMercatorViewport({
      width: 600,
      height: 800,
      latitude: feature.center[1],
      longitude: feature.center[0],
      zoom: 10,
    })

    const bounds = vp.getBounds()

    return {
      latitude: feature.center[1],
      longitude: feature.center[0],
      zoom: 12,
      bounds: {
        maxLat: bounds[1][1],
        minLat: bounds[0][1],
        maxLng: bounds[1][0],
        minLng: bounds[0][0],
      },
    }
  }
}

interface SearchComponent {
  shouldDisplayBackground?: boolean
}

export const Search: React.FC<SearchComponent> = ({ shouldDisplayBackground = false }: SearchComponent) => {
  const node = useRef<HTMLDivElement>(null)
  const [searchValue, setSearchValue] = useState('')
  const [resultsOpen, setResultsOpen] = useState(false)
  const [geoValues, setGeoValues] = useState([])
  const [results, setResults] = useState<any[]>([])
  const [isFocused, setIsFocused] = useState(false)
  const toggleOverlay = useStore((state) => state.toggleOverlay)
  const setSearchCoords = useStore((state) => state.setSearchCoords)
  const { lock, unlock } = useWindowLock()

  const handleClickOutside = (e) => {
    if (node?.current?.contains(e.target)) {
      return
    }
    handleClear()
  }

  useEffect(() => {
    if (resultsOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [resultsOpen])

  const performSearch = (query: string) => {
    const queries = [
      {
        indexName: 'listings',
        query,
        params: {
          hitsPerPage: 3,
        },
      },
      {
        indexName: 'companies',
        query,
        params: {
          hitsPerPage: 3,
        },
      },
    ]
    searchClient.multipleQueries(queries).then(({ results }) => setResults([results[0].hits, results[1].hits]))
  }

  const handleClear = (query?: any) => {
    setResultsOpen(false)
    toggleOverlay(false)
    unlock()
    setResults([])
    setGeoValues([])
    setSearchValue('')
    setIsFocused(false)
    if (query) {
      setSearchCoords(query)
    } else {
      setSearchCoords(null)
    }
  }

  const findGeocodedAddress = useDebouncedCallback(() => {
    mapbox_geocode
      .forwardGeocode({
        query: searchValue,
        mode: 'mapbox.places',
        limit: 10,
        countries: ['CA'],
        types: ['region', 'postcode', 'place', 'neighborhood'],
      })
      .send()
      .then((response) => {
        if (!resultsOpen) {
          setResultsOpen(true)
          if (shouldDisplayBackground) {
            toggleOverlay(true)
            lock()
          }
        }
        setGeoValues(response.body.features)
      })
  }, 500)

  const handleFormType = (value: React.ChangeEvent<HTMLInputElement>) => {
    const query = value.target.value
    setSearchValue(query)
    if (query.length > 3) {
      performSearch(query)
      findGeocodedAddress()
    }
  }

  return (
    <div className="relative rounded-full" ref={node}>
      <div role="group" className="relative rounded-full">
        <Label
          label="Search by address."
          name="search"
          className={clsx(
            'absolute whitespace-nowrap overflow-hidden top-3.5 lg:top-4 z-10 left-10 transition-all pointer-events-none font-normal text-darkbg ',
            isFocused ? 'top-[5px] lg:top-2 text-xs' : 'text-xs lg:text-sm',
          )}
        />
        <div className="flex md:flex-row w-full">
          <div className="relative flex-1">
            <input
              onChange={handleFormType}
              onFocus={() => setIsFocused(true)}
              onBlur={() => (!searchValue.length ? setIsFocused(false) : null)}
              value={searchValue}
              className="pr-3 pl-10 pt-3.5 lg:pt-5 pb-1 w-full text-base bg-white rounded-full appearance-none border border-smoke-900 lg:border-smoke-300 placeholder-transparent pr-4 overflow-hidden focus:outline-none focus:ring-1 focus:ring-darkbg transition-shadow"
              type="search"
            />
            <div role="presentation" aria-hidden="true" className="text-darkbg absolute top-3 lg:top-3.5 left-3.5">
              <RiSearchLine className="text-lg" size="18" />
            </div>
            {searchValue.length && handleClear ? (
              <button
                className="absolute right-1.5 lg:right-3 top-3 lg:top-4"
                onClick={() => handleClear()}
                type="button"
              >
                <span className="sr-only">Remove value</span>
                <MdClear size="16" />
              </button>
            ) : null}
          </div>
        </div>
      </div>
      {resultsOpen && (results.length || geoValues.length) ? (
        <AnimatePresence>
          <motion.div
            className="absolute rounded-md bg-white shadow-lg border w-full border-smoke-200 px-5 z-8000 mt-2 lg:mt-6 transition-all max-h-[60vh] overflow-y-scroll"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <ul className="divide-y divide-smoke-100">
              {results[0].map((result) => (
                <li key={result.id} className="flex py-5">
                  <div className="text-blue-400 mr-3 w-4 relative">
                    <ReactSVG src="/svg/icons/pinthin.svg" />
                  </div>
                  <div>
                    <Link href={`/listing/${result.id}`} passHref>
                      <a
                        className="block no-underline hover:underline overflow-ellipsis overflow-hidden"
                        onClick={() => handleClear()}
                        onKeyPress={() => handleClear()}
                        role="link"
                        tabIndex={0}
                      >
                        <Text className="text-xs lg:text-sm font-oakes mb-0.5 text-darker">
                          {(result.unitnumber ? `${result.unitnumber} - ` : '') +
                            `${result.streetnumber} ${result.streetname}, ${result.city}, ${result.country} ${result.postalzip}`}
                        </Text>
                      </a>
                    </Link>
                    <Text className="text-xs text-smoke-800 mb-0 font-oakes">Rental Unit</Text>
                  </div>
                </li>
              ))}
              {results[1].map((result) => (
                <li key={result.id} className="flex py-5">
                  <div className="text-blue-400 mr-3 w-4 relative">
                    <ReactSVG src="/svg/icons/business.svg" />
                  </div>
                  <div>
                    <Link href={`/management/${result.id}`} passHref>
                      <a
                        className="block no-underline hover:underline overflow-ellipsis overflow-hidden"
                        onClick={() => handleClear()}
                        onKeyPress={() => handleClear()}
                        role="link"
                        tabIndex={0}
                      >
                        <Text className="text-xs lg:text-sm font-oakes mb-0.5 text-darker">{result.name}</Text>
                      </a>
                    </Link>
                    <Text className="text-xs text-smoke-800 mb-0 font-oakes">Property Management</Text>
                  </div>
                </li>
              ))}
              {geoValues.map((geo: any) => {
                const isHood = geo.place_type[0] === 'neighborhood'
                return (
                  <li key={geo.id} className="flex py-5">
                    <div className="text-blue-400 mr-3 w-4 relative">
                      <ReactSVG src="/svg/icons/searchlocation.svg" />
                    </div>
                    <div>
                      <Link
                        href={
                          isHood
                            ? `/n/${geo.context['2'].short_code.toLowerCase()}/${geo.context['1'].text
                                .toLowerCase()
                                .replace(' ', '-')
                                .replace('&', '_')}/${geo.text.toLowerCase().replace(' ', '-').replace('&', '_')}/`
                            : '/explore'
                        }
                        passHref
                      >
                        <a
                          className="block no-underline hover:underline overflow-ellipsis overflow-hidden text-xs lg:text-sm font-oakes mb-0.5 text-darker"
                          onClick={isHood ? handleClear : () => handleClear(makeCoords(geo))}
                          onKeyPress={isHood ? handleClear : () => handleClear(makeCoords(geo))}
                          role="link"
                          tabIndex={0}
                        >
                          {geo.place_name}
                        </a>
                      </Link>
                      <Text className="text-xs text-smoke-800 mb-0 font-oakes">
                        {isHood ? 'Explore this neighbourhood' : 'Search this area'}
                      </Text>
                    </div>
                  </li>
                )
              })}
              <li className="flex py-6">
                <div>
                  <Link href="/new" passHref>
                    <a
                      className="block no-underline hover:underline overflow-ellipsis overflow-hidden"
                      role="link"
                      tabIndex={0}
                    >
                      <Text className="text-sm text-darker font-oakes">Did you live here? Write a review</Text>
                    </a>
                  </Link>
                </div>
              </li>
            </ul>
          </motion.div>
        </AnimatePresence>
      ) : null}
    </div>
  )
}
