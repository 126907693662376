import { motion } from 'framer-motion'
import clsx from 'clsx'

const Path = (props) => <motion.path fill="transparent" strokeWidth="3" strokeLinecap="round" {...props} />

interface Props {
  toggle: () => any
  className?: string
}

export const MenuToggle: React.FC<Props> = ({ toggle, className }: Props) => (
  <button onClick={toggle} className={className} title="Toggle Menu">
    <span className="sr-only">Toggle Menu</span>
    <svg width="23" height="23" viewBox="0 0 23 23" className={clsx('stroke-current', 'text-darkGreen')}>
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
)
