/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import { format } from 'date-fns'
import { mutate } from 'swr'
import Link from 'next/link'
import { RiDeleteBinLine } from 'react-icons/ri'
import { MdReportProblem, MdEdit } from 'react-icons/md'
import { ReactSVG } from 'react-svg'

import { Rating } from '../Rating'
import { Text } from '../Text'
import { Spinner } from '../Spinner'
import { ImageContainer } from '../ImageContainer'
import { Stat } from '../Stat'
import { IReview as ReviewProps } from '../../lib/utils/interfaces'
import { deleteReview } from '../../lib/requests/deleteReview'
import { useStore } from '../../lib/state'
import clsx from 'clsx'

const bodyContent = (content) => {
  return content.split(/\n/)
}

interface Props extends ReviewProps {
  userId?: string
  listingId: string
  type?: string
}

export const Review: React.FC<Props> = ({
  airConditioningIncluded,
  content,
  createdAt,
  dishwasherAvailable,
  dryerAvgPrice,
  gasAvgPrice,
  gasIncluded,
  hydroAvgPrice,
  hydroIncluded,
  id,
  images,
  landlordResponsive,
  landlordResponsiveStory,
  laundryAvgPrice,
  laundryIncluded,
  noisyNeighbours,
  noisyNeighboursStory,
  parkingAvailable,
  parkingAvgPrice,
  pests,
  pestsStory,
  petsWelcome,
  petsWelcomeStory,
  rating,
  shareTenancy = true,
  tenancyEnd,
  tenancyStart,
  user,
  waterAvgPrice,
  waterIncluded,
  wellMaintained,
  wellMaintainedStory,
  listingId,
  userId,
  type,
  landlordLanguages,
  landlordLanguagesStory,
  accessibility,
  accessibilityStory,
}: Props) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const setNotification = useStore((state) => state.setNotification)
  const isListing = type === 'listing'

  const handleDelete = async () => {
    const shouldDelete = confirm('Are you sure you want to delete your review?')

    if (shouldDelete) {
      setIsDeleting(true)
      try {
        await deleteReview({ listingId, reviewId: id })
        mutate(`listings/${listingId}`)
        setNotification({ type: 'success', message: 'Successfully deleted review' })
        setIsDeleting(false)
      } catch (err) {
        setNotification({ type: 'error', message: 'Error deleting review' })
        setIsDeleting(false)
      }
    }
  }

  const userCanModify = userId === user.id
  const editUrl = isListing ? `/listing/${listingId}/review/${id}/edit` : `/management/${listingId}/review/${id}/edit`
  return (
    <article className="mb-8 font-sans text-darker" id={id}>
      <div className="md:pt-4 md:pb-2 ">
        <header className="mb-1.5">
          <Text className="text-darker tracking-tight font-oakes mt-1.5">
            Posted on {format(new Date(createdAt), 'MMMM do, y')}
          </Text>
          <Rating value={rating} className="text-yellow-500" />

          {type !== 'company' && shareTenancy && tenancyStart && tenancyEnd && (
            <Text className="text-darker text-xs">
              Rented
              {tenancyStart &&
                tenancyEnd &&
                ` from ${format(new Date(tenancyStart), 'MMMM do, y')} until ${format(
                  new Date(tenancyEnd),
                  'MMMM do, y',
                )}`}
            </Text>
          )}
        </header>
        <div className="mb-8 prose text-current max-w-full break-normal !leading-[1.7em] text-sm">
          {bodyContent(content).map((paragraph, i) => (
            <Text key={i}>{paragraph}</Text>
          ))}
        </div>
        {images.length ? (
          <div className="flex items-start mb-8 flex-wrap">
            <ImageContainer files={images} />
          </div>
        ) : null}

        <div className="mb">
          <div className="gap-x-6 gap-y-12 grid grid-cols-2 md:grid-cols-3 mb-8">
            {isListing ? (
              <>
                {typeof gasIncluded === 'boolean' || gasAvgPrice !== 0 ? (
                  <div className="flex items-start col-span-1">
                    <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                      <ReactSVG src="/svg/icons/gas.svg" title="Good Neighbour" />
                    </div>
                    <div className="ml-4">
                      <Text className="tracking-tight font-oakes">
                        {gasIncluded || gasAvgPrice === 0 ? 'Gas Included' : `Gas approx $${gasAvgPrice} per month`}
                      </Text>
                    </div>
                  </div>
                ) : null}

                {typeof waterIncluded === 'boolean' || waterAvgPrice !== 0 ? (
                  <div className="flex items-start col-span-1">
                    <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                      <ReactSVG src="/svg/icons/water.svg" title="Good Neighbour" />
                    </div>
                    <div className="ml-4">
                      <Text className="tracking-tight font-oakes">
                        {waterIncluded || waterAvgPrice === 0
                          ? 'Water Included'
                          : `Water approx $${waterAvgPrice} per month`}
                      </Text>
                    </div>
                  </div>
                ) : null}

                {typeof hydroIncluded === 'boolean' || hydroAvgPrice !== 0 ? (
                  <div className="flex items-start col-span-1">
                    <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                      <ReactSVG src="/svg/icons/hydro.svg" title="Good Neighbour" />
                    </div>
                    <div className="ml-4">
                      <Text className="tracking-tight font-oakes">
                        {hydroIncluded || hydroAvgPrice === 0
                          ? 'Electricity Included'
                          : `Electricity approx $${hydroAvgPrice} per month`}
                      </Text>
                    </div>
                  </div>
                ) : null}

                {typeof dishwasherAvailable === 'boolean' ? (
                  <div className="flex items-start col-span-1">
                    <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                      <ReactSVG src="/svg/icons/dishwasher.svg" title="Good Neighbour" />
                    </div>
                    <div className="ml-4">
                      <Text className="tracking-tight font-oakes">
                        {dishwasherAvailable ? 'Dishwasher Included' : 'No dishwasher'}
                      </Text>
                    </div>
                  </div>
                ) : null}

                <div className="flex items-start col-span-1">
                  <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                    <ReactSVG src="/svg/icons/airconditioner.svg" title="Good Neighbour" />
                  </div>
                  <div className="ml-4 flex-1">
                    <Text className="tracking-tight font-oakes">
                      {airConditioningIncluded ? 'Air Conditioning Included' : 'No air conditioning'}
                    </Text>
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex items-start col-span-1">
              <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                <ReactSVG src="/svg/icons/parking.svg" title="Good Neighbour" />
              </div>
              <div className="ml-4">
                <Text className="tracking-tight font-oakes">
                  {parkingAvailable ? 'Parking Available' : 'Parking not available'}
                  {parkingAvgPrice ? `: approx $${hydroAvgPrice} per month` : null}
                </Text>
              </div>
            </div>
            {typeof laundryIncluded === 'boolean' || laundryAvgPrice !== 0 ? (
              <div className="flex items-start col-span-1">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/laundry.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4">
                  <Text className="tracking-tight font-oakes">
                    {laundryIncluded ? 'Laundry Available' : 'Laundry not available'}
                  </Text>

                  {laundryIncluded ? (
                    <>
                      <Text className="text-sm !leading-[1.7em] mt-1">
                        {laundryAvgPrice ? `Washing Machine $${laundryAvgPrice} per load` : `Washing Machine included`}
                      </Text>
                      <Text className="text-sm !leading-[1.7em]">
                        {dryerAvgPrice ? `Dryer $${dryerAvgPrice} per load` : `Dryer included`}
                      </Text>
                    </>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>

          <div className="mb-6 space-y-8">
            {typeof petsWelcome === 'boolean' || petsWelcomeStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/pets.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1 justify-center flex flex-col">
                  {typeof petsWelcome === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {petsWelcome ? 'Pets are welcome' : 'No pets welcome'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Pets</Text>
                  )}

                  {petsWelcomeStory ? <Text className="text-sm !leading-[1.7em] mt-1">{petsWelcomeStory}</Text> : null}
                </div>
              </div>
            ) : null}

            {typeof landlordResponsive === 'boolean' || landlordResponsiveStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/landlord.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof landlordResponsive === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {landlordResponsive
                        ? 'Landlord was responsive to requests'
                        : 'Landlord was not responsive to requests'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Landlord</Text>
                  )}
                  {landlordResponsiveStory ? (
                    <Text className="text-sm !leading-[1.7em] mt-1">{landlordResponsiveStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof noisyNeighbours === 'boolean' || noisyNeighboursStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/noisy.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof noisyNeighbours === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {noisyNeighbours ? 'Neighbours are noisy.' : 'No noisy neighbours here.'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Neighbours</Text>
                  )}

                  {noisyNeighboursStory ? (
                    <Text className="text-sm !leading-[1.7em] mt-1">{noisyNeighboursStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof accessibility === 'boolean' || accessibilityStory?.length ? (
              <div
                className={clsx(
                  'flex',
                  typeof accessibility === 'boolean' ? 'items-start lg:items-center' : 'items-start',
                )}
              >
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/accessibility.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof accessibility === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {accessibility ? 'Accessibility Friendly.' : 'Not Accessiblity Friendly.'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Accessibility</Text>
                  )}

                  {accessibilityStory ? (
                    <Text className="text-sm !leading-[1.7em] mt-1">{accessibilityStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof landlordLanguages === 'boolean' || landlordLanguagesStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-violet-50">
                  <ReactSVG src="/svg/icons/languages.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof landlordLanguages === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {landlordLanguages
                        ? 'Landlord supports other languages.'
                        : 'Landlord does not support other languages.'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Landlord Languages</Text>
                  )}

                  {landlordLanguagesStory ? (
                    <Text className="text-sm !leading-[1.7em] mt-1">{landlordLanguagesStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}

            {typeof pests === 'boolean' || pestsStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-green-100">
                  <ReactSVG src="/svg/icons/pests.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof pests === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {pests
                        ? 'There were pests, insects or unwanted animals'
                        : 'No pests, insects or unwanted animals'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Pests</Text>
                  )}

                  {pestsStory ? <Text className="text-sm !leading-[1.7em] mt-1">{pestsStory}</Text> : null}
                </div>
              </div>
            ) : null}

            {typeof wellMaintained === 'boolean' || wellMaintainedStory?.length ? (
              <div className="flex items-start">
                <div className="w-8 h-8 rounded-full p-2 flex-shrink-0 bg-yellow-100">
                  <ReactSVG src="/svg/icons/maintain.svg" title="Good Neighbour" />
                </div>
                <div className="ml-4 flex-1">
                  {typeof wellMaintained === 'boolean' ? (
                    <Text className="tracking-tight font-oakes">
                      {wellMaintained ? 'Property was well maintained' : 'Property was not well maintained'}
                    </Text>
                  ) : (
                    <Text className="tracking-tight font-oakes">Property Maintenance</Text>
                  )}

                  {wellMaintainedStory ? (
                    <Text className="text-sm !leading-[1.7em] mt-1">{wellMaintainedStory}</Text>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row sm:space-x-4 justify-end items-center md:border-t px-4 pt-4 md:px-6">
        <div className="flex space-x-6">
          {userCanModify && (
            <>
              <Link href={editUrl} passHref>
                <a className="no-underline text-xs text-green-500 hover:text-green-400 flex items-center border-b border-current">
                  <MdEdit className="mr-1" />
                  Edit
                </a>
              </Link>
              <button
                className="no-underline text-xs text-red-500 hover:text-red-400 flex items-center border-b border-current py-1"
                onClick={handleDelete}
              >
                <RiDeleteBinLine className="mr-1" />
                Delete
              </button>
            </>
          )}
          {/* <Link href={`/contact?type=review_issue&review=${id}`} passHref>
            <a className="no-underline text-xs text-smoke-600 hover:text-gray-200 flex items-center border-b border-current py-1">
              <RiShareBoxFill className="mr-1" /> Share
            </a>
          </Link> */}
          <Link href={`/contact?type=review_issue&rid=${id}`} passHref>
            <a className="no-underline text-xs text-smoke-600 hover:text-gray-200 flex items-center border-b border-current py-1">
              <MdReportProblem className="mr-1" />
              Report
            </a>
          </Link>
        </div>
      </div>

      {isDeleting && <Spinner />}
    </article>
  )
}

Review.displayName = 'Review'
