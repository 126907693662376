import Carousel, { Modal, ModalGateway } from 'react-images'
import { useState } from 'react'

import { IPhoto } from 'lib/utils/interfaces'
export interface ImageProps {
  files: IPhoto[]
}

const IMAGE_ENDPOINT = process.env.NEXT_PUBLIC_IMAGEKIT_ENDPOINT

function getAltText(props) {
  return props.data.alt
}

export const FooterCaption = (props) => {
  // eslint-disable-next-line react/prop-types
  return <span>{props?.currentView?.alt}</span>
}

export const ImageContainer: React.FC<ImageProps> = ({ files }: ImageProps) => {
  const [modalIsOpen, toggleModal] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const imageGroup = files.map((image) => ({
    source: `${IMAGE_ENDPOINT}${image.filePath}`,
    id: image.id,
    name: image.fileName,
  }))

  const handleToggleModal = (index: number) => {
    toggleModal(!modalIsOpen)
    setSelectedIndex(index)
  }

  return (
    <>
      <div className="flex flex-wrap">
        {imageGroup.map(({ source, id, name }, j) => (
          <button key={id} onClick={() => handleToggleModal(j)} className="mr-2 mb-2">
            <img
              src={source + '?tr=w-300,ar-4-3'}
              alt={name}
              className="w-24 h-24 md:w-28 md:h-28 object-cover pointer rounded-xl shadow hover:shadow-lg transform-gpu hover:translate-y-0.5 transition-transform"
            />
          </button>
        ))}
      </div>

      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={() => handleToggleModal(0)} allowFullscreen={false}>
            <Carousel
              views={imageGroup}
              currentIndex={selectedIndex}
              formatters={{ getAltText }}
              components={{ FooterCaption }}
              styles={{
                container: (base) => ({
                  ...base,
                  height: '100vh',
                }),
                view: (base) => ({
                  ...base,
                  alignItems: 'center',
                  display: 'flex ',
                  height: 'calc(100vh - 54px)',
                  justifyContent: 'center',

                  '& > img': {
                    maxHeight: 'calc(100vh - 90px)',
                  },
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}
