interface Props {
  unitnumber: string | null
  streetnumber: string
  streetname: string
  city: string
  region: string
  country: string
  postalzip: string
}

export default function formatAddress({
  unitnumber,
  streetnumber,
  streetname,
  city,
  region,
  country,
  postalzip,
}: Props): string {
  return (
    (unitnumber ? `${unitnumber} - ` : '') + `${streetnumber} ${streetname}, ${city} ${region}, ${country} ${postalzip}`
  )
}
