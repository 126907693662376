import { useState, useEffect } from 'react'

export function useWindowLock() {
  const [scrollTop, setScrollTop] = useState(0)
  const [scrollValue, setScrollValue] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollTop(window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  })

  const lock = () => {
    setScrollValue(scrollTop)
    document.body.style.position = 'fixed'
    document.body.style.width = '100%'
    document.body.style.top = `-${scrollTop}px`
  }

  const unlock = () => {
    document.body.style.position = ''
    document.body.style.top = ''
    document.body.style.width = ''
    window.scrollTo(0, scrollValue || 0 * -1)
  }

  return { scrollTop, lock, unlock, scrollValue }
}
