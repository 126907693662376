import clsx from 'clsx'

interface Props {
  isInvalid?: boolean
  name: string
  label: React.ReactNode
  isRequired?: boolean
  hideLabel?: boolean
  className?: string
  id?: string
}

export const Label: React.FC<Props> = ({ isInvalid, name, label, isRequired, hideLabel, className, id }: Props) => {
  const classNames = clsx(hideLabel && 'sr-only', 'inline-block font-oakes', className)

  return (
    <label className={classNames} data-invalid={isInvalid} htmlFor={name} id={id}>
      {label}
      {isRequired && (
        <div role="presentation" aria-hidden="true" className="inline-block ml-1">
          *
        </div>
      )}
    </label>
  )
}

Label.displayName = 'Label'
