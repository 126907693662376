import { motion } from 'framer-motion'

type Props = {
  submitCallback: (...args: any[]) => void
  image: React.ReactNode
  title: string
  description: string
}

export const TypeCard: React.FC<Props> = ({ submitCallback, image, title, description }: Props) => (
  <motion.button
    whileHover={{ scale: 1.01 }}
    transition={{ type: 'spring', bounce: 0.5 }}
    whileTap={{ scale: 0.98 }}
    onClick={submitCallback}
    className="bg-white flex-auto md:py-4 md:px-5 py-3 px-3 rounded-xl relative hover:shadow-xl transition-shadow duration-500 border border-smoke-50 shadow-lg w-full md:w-1/2 flex sm:block outline-none ring-0 focus:outline-none"
  >
    <div className="sm:h-64 flex-1 sm:flex-none mr-4">{image}</div>
    <div className="w-3/4 sm:w-full text-left md:text-center flex flex-col md:items-center">
      <span className="block flex-1 text-darkbg md:text-xl font-oakes text-lg tracking-tight">{title}</span>
      <span className="md:px-6 mt-1 block text-sm text-smoke-800">{description}</span>
    </div>
  </motion.button>
)
